import types from "../mutation-types";
import ScoreApi from '../../api/scores'

export const state = {
    score: {},
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        isUpdating: false,
    },


    leaderboard: [],
    uiFlags_leaderboard: {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        isUpdating: false,
    },
};

// list of getters
const getters = {
    getUIFlags(_state){
        return _state.uiFlags;
    },
    getScore(_state){
        return _state.score;
    },
    getUIFlagsLeaderboard(_state){
        return _state.uiFlags_leaderboard;
    },
    getLeaderboard(_state){
        return _state.leaderboard;
    },
}


// actions list
const actions = {
    fetchScore: async function({commit},queryParams){
        commit(types.SET_SCORE_UI_FLAG,{isFetching: true});
        try {
            const {data} = await ScoreApi.getScores();
            commit(types.SET_SCORE, data);
            commit(types.SET_SCORE_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_SCORE_UI_FLAG,{isFetching: false});
        }
    },
    fetchLeaderboardList: async function({commit},queryParams){
        commit(types.SET_LEADERBOARD_UI_FLAG,{isFetching: true});
        try {
            const {data} = await ScoreApi.getLeaderBoardList();
            console.log(data);
            if (data.data) {
                commit(types.SET_LEADERBOARD, data.data);
            }
            commit(types.SET_LEADERBOARD_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_LEADERBOARD_UI_FLAG,{isFetching: false});
        }
    },

}


// list of mutations
const mutations = {
    // mutate UI flags
    [types.SET_SCORE_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },
    [types.SET_LEADERBOARD_UI_FLAG](_state, data) {
        _state.uiFlags_leaderboard = {
          ..._state.uiFlags_leaderboard,
          ...data,
        };
    },
    // set data
    [types.SET_SCORE](_state, data) {
        _state.score = data;
    },
    [types.SET_LEADERBOARD](_state, data) {
        _state.leaderboard = data;
    },


}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
