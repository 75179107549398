import { render, staticRenderFns } from "./SquidLevelsModal.vue?vue&type=template&id=4a5e1678&scoped=true&"
import script from "./SquidLevelsModal.vue?vue&type=script&lang=js&"
export * from "./SquidLevelsModal.vue?vue&type=script&lang=js&"
import style0 from "./SquidLevelsModal.vue?vue&type=style&index=0&id=4a5e1678&lang=scss&scoped=true&"
import style1 from "./SquidLevelsModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5e1678",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a5e1678')) {
      api.createRecord('4a5e1678', component.options)
    } else {
      api.reload('4a5e1678', component.options)
    }
    module.hot.accept("./SquidLevelsModal.vue?vue&type=template&id=4a5e1678&scoped=true&", function () {
      api.rerender('4a5e1678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/goal_dashboard/SquidLevelsModal.vue"
export default component.exports