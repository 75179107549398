var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.uiFlags.isFetching
        ? _c(
            "div",
            { staticClass: "stats_container" },
            _vm._l(_vm.formatedStats, function(item, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "stats_wrap",
                  class: "stats_" + item.label
                },
                [
                  _c("div", { staticClass: "stats" }, [
                    _c(
                      "div",
                      { staticClass: "logo" },
                      [
                        _c("fluent-icon", {
                          attrs: { icon: item.icon, viewBox: "0 0 40 40" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "count" }, [
                        _vm._v(_vm._s(item.value) + " "),
                        item.unit
                          ? _c("span", { staticClass: "unit" }, [
                              _vm._v(_vm._s(item.unit))
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "label" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            return _vm.onClickStat(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.btn_label))]
                    )
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlags.isFetching
        ? _c("woot-loading-state", {
            attrs: { message: _vm.$t("D_BOARD.STATS.LOADING") }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }