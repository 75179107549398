<template>
  <div class="progress">
    <div class="progress_bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100; // Ensure progress is between 0 and 100
      },
    },
  },
};
</script>

<style scoped lang="scss">
.progress{
    height: 12px;
    width: 100%;
    margin: auto;
    background: #F2E7FE;
    border-radius: 16px;
    .progress_bar{
        background: linear-gradient(45deg, #8D25F6, #CDA2FB);
        border-radius: 16px;
        height: 100%;
    }
}
</style>

