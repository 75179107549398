var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "express_container" }, [
        _c(
          "table",
          [
            _c("tr", { staticClass: "flow-header" }, [
              _c("td", { staticClass: "center" }, [
                _c("h1", { staticClass: "name" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("FLOW.BOT_ID")) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "center" }, [
                _c("h1", { staticClass: "name" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("FLOW.NAME")) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "center" }, [
                _c("h1", { staticClass: "name flex" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("FLOW.MODIFIED")) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.expressBots, function(flowItem, index) {
              return _c(
                "tr",
                {
                  key: index,
                  staticClass: "flow-box",
                  on: {
                    click: function($event) {
                      return _vm.itemClick(flowItem)
                    }
                  }
                },
                [
                  _c("td", { staticClass: "left" }, [
                    _c("h1", { staticClass: "name" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(flowItem.bot_id) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "left" }, [
                    _c("h1", { staticClass: "name" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(flowItem.name) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "center" }, [
                    _c("h1", { staticClass: "date" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm
                              .moment(flowItem.updated_at)
                              .local()
                              .fromNow()
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("ExpressModadlWrap")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }