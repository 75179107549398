/* global axios */
import ApiClient from './ApiClient';

class ScoreAPI extends ApiClient {
  constructor() {
    super('scores', { accountScoped: true });
  }

  getScores(){
    return axios.get(`${this.url}`);
  }
  getLeaderBoardList(){
    return axios.get(`${this.url}/leaderboard`);
  }

}

export default new ScoreAPI();

