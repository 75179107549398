var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-9 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.WHATSAPP.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.WHATSAPP.DESC")
        }
      }),
      _vm._v(" "),
      _vm.provider === "twilio"
        ? _c("twilio", { attrs: { type: "whatsapp" } })
        : _vm.provider === "360dialog"
        ? _c("three-sixty-dialog-whatsapp", {
            attrs: { selectedProvider: _vm.provider }
          })
        : _vm.provider === "vonage"
        ? _c("GrantWhatsapp")
        : _c("cloud-whatsapp")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }