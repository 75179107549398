<template>
      <!-- modalMainContainerClass="squidLevelsModal" -->
    <modal
      :show.sync="isOpen"
      :on-close="onClose"
      :modalMainContainerClass="modalMainContainerClass"
      closeBtnClass="modal_close_btn_left template_modal_close_btn"
      closeIcon="chevron-left"
    >
    <div class="header_left_close">
        <h2 class="header_title">{{title}}</h2>
    </div>
    <div class="template_modal_body">
        <slot />
    </div>
    </modal>
</template>



<script>
import Modal from './Modal.vue';


export default {
    components:{
        Modal,
    },
    props:{
        isOpen: {
            type: Boolean,
            default: false
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        title:{
            type: String,
            default: ""
        },
        modalMainContainerClass:{
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.header_left_close{
    margin-left: 28px;
    .header_title{
        font-weight: 700;
        font-size: 20px;
        color: #1F1F1F;
    }
}
.template_modal_body{
    max-height: 70vh;
    overflow: auto;
}
</style>

<style>
.template_modal_close_btn{
    top: 19px !important;
    left: 15px !important;
}
</style>
