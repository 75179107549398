var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "section",
        { staticClass: "flex-container flex-dir-column medium-flex-dir-row" },
        [
          _c(
            "div",
            [
              _c("multiselect", {
                staticClass: "small-12 heat_map",
                attrs: {
                  options: _vm.metricOptions,
                  "track-by": "id",
                  label: "name",
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "hide-selected": true,
                  placeholder: "Pick Inbox",
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
                },
                on: { select: _vm.changeFilterSelection },
                model: {
                  value: _vm.selected_metric,
                  callback: function($$v) {
                    _vm.selected_metric = $$v
                  },
                  expression: "selected_metric"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("HeatMapChart", {
        attrs: { chartId: "salesHeatmapChart", series: _vm.chartSeries }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }