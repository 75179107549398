var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "score_benifit_container" }, [
    _c("aside", { staticClass: "benifit_wrap" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("D_BOARD.SCORE.TITLE")))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "logo_wrap" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", { attrs: { src: _vm.score.rank_logo, alt: "" } })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "benifit_container" }, [
        _c("h3", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.score.rank_name))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "benifits" },
          _vm._l(_vm.benifits, function(item) {
            return _c("li", { key: item, staticClass: "benifit" }, [
              _c("p", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("fluent-icon", { attrs: { icon: item.icon } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _vm._v(_vm._s(item.value))
                ])
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "show_btn", on: { click: _vm.toggleBenifitTab } },
          [_vm._v(_vm._s(_vm.$t("D_BOARD.SCORE.BTN")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "tasks_info_container" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("D_BOARD.STEP_INFO.TITLE")))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "task_label_info" }, [
        _c("p", { staticClass: "task_label" }, [
          _vm._v(
            "\n              " +
              _vm._s(_vm.score.task_count - _vm.score.task_pending_count) +
              "\n              " +
              _vm._s(_vm.$t("D_BOARD.STEP_INFO.LABLE_OF")) +
              "\n              " +
              _vm._s(_vm.score.task_count) +
              "\n              " +
              _vm._s(_vm.$t("D_BOARD.STEP_INFO.LABLE_END")) +
              "\n          "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress" }, [
          _c("div", {
            staticClass: "progress_bar",
            style: { width: _vm.complete_percent + "%" }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "level_name" }, [
          _vm._v(_vm._s(_vm.score.level_name))
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn_3d",
            on: {
              click: function($event) {
                return _vm.onClickCompleteNow("complete_tasks")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("D_BOARD.STEP_INFO.BTN")))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sales_label_info" }, [
        _c("p", { staticClass: "task_label" }, [
          _c("span", [_vm._v(_vm._s(_vm.score.total_gmv))]),
          _vm._v(
            "\n              /" +
              _vm._s(_vm.score.level_target_gmv) +
              " RM\n          "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress" }, [
          _c("div", {
            staticClass: "progress_bar",
            style: { width: _vm.gmv_complete_percent + "%" }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "level_name" }, [
          _vm._v(
            "\n              " +
              _vm._s(_vm.$t("D_BOARD.STEP_INFO.INSPIRE_PART_1")) +
              "\n              " +
              _vm._s(_vm.score.level_target_gmv) +
              "\n              " +
              _vm._s(_vm.$t("D_BOARD.STEP_INFO.INSPIRE_PART_2")) +
              "\n          "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }