var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("section", [
            _c("div", [
              _c(
                "label",
                { staticClass: "font_sm filter_box", attrs: { for: "" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("D_BOARD.DATE_LABEL")) +
                      "\n          "
                  ),
                  _c("FilterSelector", {
                    attrs: {
                      filterItemsList: _vm.filterItemsList,
                      hideGroupby: false
                    },
                    on: { "date-range-change": _vm.onDateRangeChange }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("Stats"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "leader_benifit" },
            [
              _c(
                "div",
                [
                  _vm.isGoalBenifitTab
                    ? _c("div", [
                        _c("h3", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("D_BOARD.GOAL.TITLE")))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "option" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.score.level_name) +
                              "\n            "
                          ),
                          _c(
                            "span",
                            { staticClass: "icon" },
                            [
                              _c("fluent-icon", {
                                attrs: { icon: "chevron-down" }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "goal_card_wrap" }, [
                          _c("div", { staticClass: "goal_card" }, [
                            _c("div", [
                              _c("div", { staticClass: "circular-progress" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "progress-svg",
                                    attrs: { viewBox: "0 0 36 36" }
                                  },
                                  [
                                    _c(
                                      "defs",
                                      [
                                        _c(
                                          "linearGradient",
                                          {
                                            attrs: {
                                              id: "progress-gradient",
                                              x1: "0%",
                                              y1: "0%",
                                              x2: "100%",
                                              y2: "0%"
                                            }
                                          },
                                          [
                                            _c("stop", {
                                              attrs: {
                                                offset: "0%",
                                                "stop-color": "#8D25F6"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("stop", {
                                              attrs: {
                                                offset: "100%",
                                                "stop-color": "#CDA2FB"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("path", {
                                      staticClass: "progress-bg",
                                      attrs: {
                                        d:
                                          "M18 2.0845\n                        a 15.9155 15.9155 0 1 1 0 31.831\n                        a 15.9155 15.9155 0 1 1 0 -31.831"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      staticClass: "progress-bar",
                                      attrs: {
                                        "stroke-dasharray":
                                          _vm.complete_percent + ", 100",
                                        stroke: "url(#progress-gradient)",
                                        d:
                                          "M18 2.0845\n                        a 15.9155 15.9155 0 1 1 0 31.831\n                        a 15.9155 15.9155 0 1 1 0 -31.831"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(0)
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card_body" }, [
                              _c("div", { staticClass: "heading" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.complete_percent) +
                                    "%\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "task_label" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.score.task_count -
                                        _vm.score.task_pending_count
                                    ) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.$t("D_BOARD.STEP_INFO.LABLE_OF")
                                    ) +
                                    "\n                  " +
                                    _vm._s(_vm.score.task_count) +
                                    "\n                  " +
                                    _vm._s(
                                      _vm.$t("D_BOARD.STEP_INFO.LABLE_END")
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn_3d",
                                    on: {
                                      click: function($event) {
                                        return _vm.onShowModalSeries(
                                          "complete_tasks"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("D_BOARD.STEP_INFO.BTN"))
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "badge_cards" }, [
                          _c("div", { staticClass: "badge_card" }, [
                            _c("div", { staticClass: "badge_content" }, [
                              _c("div", [
                                _c("p", { staticClass: "badge_title" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("D_BOARD.GOAL.BADGE_TITLE"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "badge_label" }, [
                                  _vm._v(_vm._s(_vm.score.rank_name))
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._m(1)
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "woot-button",
                                  {
                                    staticClass:
                                      "btn_outline button--dashboard-outline",
                                    attrs: { variant: "outline" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onShowModalSeries(
                                          "squid_level"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("D_BOARD.GOAL.BADGE_BTN")
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "badge_card" }, [
                            _c("div", { staticClass: "badge_content" }, [
                              _c("div", [
                                _c("p", { staticClass: "badge_title" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("D_BOARD.GOAL.LEADER_TITLE"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "badge_label" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.score.leaderboard_position) +
                                      "\n                    "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "position_suffix" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getLiteralRanking(
                                            _vm.score.leaderboard_position
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._m(2)
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "woot-button",
                                  {
                                    staticClass:
                                      "btn_outline button--dashboard-outline",
                                    attrs: { variant: "outline" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("D_BOARD.GOAL.LEADER_BTN")
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    : _c("ScoreBenifits", {
                        attrs: {
                          toggleBenifitTab: _vm.toggleBenifitTab,
                          onClickCompleteNow: _vm.onShowModalSeries
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c("LeaderBoard")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModalSeries
        ? _c(
            "div",
            [
              _vm.activeModal === "squid_level"
                ? _c("SquidLevelsModal", {
                    attrs: {
                      isOpen: _vm.showModalSeries,
                      "on-close": _vm.onCLoseModals
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeModal === "complete_tasks"
                ? _c("TasksCompleteModal", {
                    attrs: {
                      isOpen: _vm.showModalSeries,
                      "on-close": _vm.onCLoseModals
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ContestEventPopup")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress-content" }, [
      _c("img", {
        attrs: { src: require("dashboard/assets/images/octo_img.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("dashboard/assets/images/badge.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          src: require("dashboard/assets/images/leader_board.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }