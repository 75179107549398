import AppContainer from './Dashboard';
import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { frontendURL } from '../../helper/URLHelper';
import sessionRoutes from './session_boarding/session.routes';
import creditRoutes from './credit_balance/credit_balance.routes';
import catalogueRoutes from './catalogue/catalogue.routes';
import arrangeRoutes from './arrange/arrange.route';
import goal_dashboardRoutes from './goal_dashboard/goal_dashboard.routes';

export default {
  routes: [
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        ...goal_dashboardRoutes.routes,
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...notificationRoutes,
        ...sessionRoutes.routes,
        ...creditRoutes.routes,
        ...catalogueRoutes.routes,
        ...arrangeRoutes.routes,
      ],
    },
  ],
};

