var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ModalTemplate",
        {
          attrs: {
            isOpen: _vm.isOpen,
            "on-close": _vm.onClose,
            modalMainContainerClass: "taskCompleteModal",
            closeBtnClass: "modal_close_btn_left",
            closeIcon: "chevron-left",
            title: _vm.$t("D_BOARD.COMPLETE_TASKS_MODAL.TITLE")
          }
        },
        [
          _c("div", { staticClass: "task_modal_title" }, [
            _c("h2", [
              _vm._v(
                _vm._s(_vm.$t("D_BOARD.COMPLETE_TASKS_MODAL.CONTENT_TITLE_1"))
              )
            ]),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                _vm._s(_vm.$t("D_BOARD.COMPLETE_TASKS_MODAL.CONTENT_TITLE_2"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "logo_wrap" }, [
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                attrs: {
                  src: require("dashboard/assets/images/lavel_logo_placeholder.png"),
                  alt: ""
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tasks_list" }, [
            _c(
              "ul",
              _vm._l(_vm.score.level_events, function(task) {
                return _c("li", { key: task.title }, [
                  _c("img", {
                    staticClass: "bg_img",
                    attrs: {
                      src: require("dashboard/assets/images/task_mask_img.png"),
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(task.name) +
                        "\n                      "
                    ),
                    task.completed
                      ? _c(
                          "span",
                          { staticClass: "check_icon" },
                          [_c("fluent-icon", { attrs: { icon: "checkmark" } })],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(_vm._s(task.description))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "task_btns" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: task.kb_url, target: "_blank" } },
                        [
                          _c(
                            "woot-button",
                            {
                              attrs: {
                                "class-names":
                                  "button--dashboard-outline task_btn task_btn_outline",
                                variant: "outline"
                              }
                            },
                            [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(
                                    _vm.$t(
                                      "D_BOARD.COMPLETE_TASKS_MODAL.LEARN_BTN"
                                    )
                                  ) +
                                  "\n                          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: task.page_url, target: "_blank" } },
                        [
                          _c(
                            "woot-button",
                            {
                              attrs: {
                                disabled: true,
                                "class-names": "task_btn",
                                variant: "primary"
                              }
                            },
                            [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(
                                    _vm.$t(
                                      "D_BOARD.COMPLETE_TASKS_MODAL.START_BTN"
                                    )
                                  ) +
                                  "\n                          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }