
const CryptoJS = require('crypto-js');
import axiosExpress from "axios"

const baseUrl = "https://flowbot.peasy.ai";
const baseServerUrl = "https://chatdev.peasy.ai/api/v1";
const baseServerProdUrl = "https://chat.peasy.ai/api/v1";

const generateFlowAuthToken = (userid,API_KEY) =>{
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify({userid}),
    API_KEY
  ).toString();
  return encrypted;
}

const FlowBot = {
    // template is optional
    createFlow: async function ({ userId, name, accountId,template={},flow_api_key}) {
        try {
        const url = `${baseUrl}/create-bot`;
        // Prepare data for the request
        const data = new URLSearchParams();
        data.append("userid", userId);
        data.append("name", name);
        data.append("template", "default"); // no need the templated id, the data will be updated from template when call export
        data.append("accountid", accountId);

        // Axios request configuration
        const config = {
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: generateFlowAuthToken(userId,flow_api_key),
          },
          data,
          url,
        };
        const result = await axiosExpress(config);


      return {
        isSuccess: true,
        data: result.data,
      };
    } catch (error) {
      return {
        isSuccess: false,
        data: error.message || "An error occurred",
      };
    }
  },

    exportUpdateFlow : async function(botId,flowData, template = null, flow_api_key,userId) {
    let response = {};
    try {
        const url = `${baseUrl}/update-bot`;

        // Prepare data for the request
        const data = new URLSearchParams();
        data.append("botid", botId);

        if (template === null) {
        data.append("flowdata", JSON.stringify(flowData));
        } else {
        data.append("template", template);
        }

        // Axios request configuration
        const config = {
            method: "put",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: generateFlowAuthToken(userId,flow_api_key),
            },
            data,
            url,
        };

        // Make the request
        const result = await axiosExpress(config);

        // Handle success
        response.status = "ok";
        response.data = result.data;
        return response;

    } catch (error) {
        // Handle error
        response.status = "error";
        response.message = error.message || "An error occurred";
    }

    return response;
    },


}

const ExpressBot = {
    createExpressBot: async function(accountId,api_access_token,payload,isDev){
      let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
      try {
        const config = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses`,
          data:payload,
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
    updateExpressBot: async function(accountId,api_access_token,form_json,express_bot_id,isDev){
      let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
      try {
        const config = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses/${express_bot_id}`,
          data:{
            bot_express:{
              uploaded: form_json.uploaded,
              form_data: form_json.form_data,
            }
          },
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
    getExpressBotById: async function(express_bot_DB_id,accountId,api_access_token,isDev){
      try {
        let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
        const config = {
          headers: {
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses/${express_bot_DB_id}`,
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
    getExpressBotList: async function(accountId,api_access_token,isDev){
      try {
        let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
        const config = {
          headers: {
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses`,
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
    getExpressBotTempByTempName: async function(accountId,api_access_token,template_name,isDev){
      try {
        let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
        const config = {
          headers: {
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses/template?name=${template_name}`,
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
    getOriginalTemplateBotById: async function(express_bot_DB_id,accountId,api_access_token,isDev){
      try {
        let mainUrl = isDev ? baseServerUrl : baseServerProdUrl;
        const config = {
          headers: {
            ['api-access-token']: api_access_token,
          },
          url:`${mainUrl}/accounts/${accountId}/bot_expresses/${express_bot_DB_id}?bot_template=${true}`,
        };
        const {data} = await axiosExpress(config);
        return{isSuccess:true,data}
      } catch (error) {
        console.log(error);
        return{isSuccess:false,message:error.message}
      }
    },
  }


  const modifyBot = (originalBot,formJson,hiddenJson) =>{
    const data = {...originalBot};
    const btnsTobeDeletedFromLinks = [];
    try {
        for(const formNodeName in formJson){
            const formNode = formJson[formNodeName];
            for (const nodeId in data.nodes) {
                const node = data.nodes[nodeId];
                if (node.data && node.data.customName === formNodeName) {
                  // console.log(formNodeName);
                  // update contents
                  if (formNode.type === "content") {
                      for(let i=0;i<formNode.contents.length;i++){
                          const singleContent = formNode.contents[i];
                          const nodeContent = node.data.contents[i];
                          switch (singleContent.type) {
                              case 'card':
                                  nodeContent.title = singleContent.title;
                                  nodeContent.subtitle = singleContent.subtitle;

                                  // Handle buttons
                                  const cardBtnRemoveList =[];
                                  nodeContent.actionButtons.forEach((btn,ctnBtnIndex)=>{
                                      // Find the matching button in `formNode` using `originalBtn`
                                    const matchingButton = singleContent.actionButtons.find(
                                      (actionBtn) => actionBtn.originalBtn === btn.buttonTitle
                                    );

                                    if (matchingButton) {
                                      // Update button if visibility is true
                                      if (matchingButton.visibility) {
                                        node.data.contents[i].actionButtons[ctnBtnIndex] = {
                                          ...btn,
                                          buttonTitle: matchingButton.title,
                                          buttonValue: matchingButton.title,
                                        };
                                      } else if (matchingButton.visibility === false) {
                                        // Remove button entirely if visibility is false
                                        const originalBtn = btn.buttonTitle;
                                        cardBtnRemoveList.push(originalBtn);
                                        // remove btn with either user text or original text
                                        btnsTobeDeletedFromLinks.push(originalBtn,matchingButton.title);
                                      }
                                    }

                                  })
                                nodeContent.actionButtons=nodeContent.actionButtons.filter(nodeBtn=>!cardBtnRemoveList.includes(nodeBtn.buttonTitle))
                                break;

                              case "text":
                                  nodeContent.value = singleContent.value;
                                  break;

                              case "category_list":
                                  nodeContent.title = singleContent.title;
                                  nodeContent.body = singleContent.body;
                                  nodeContent.footer = singleContent.footer;
                                  nodeContent.action.sections = singleContent.sections;
                                  nodeContent.button = singleContent.button;

                                  break;

                              case "image":
                                  nodeContent.title = singleContent.title;
                                  break;

                              default:
                                  console.warn(`Unsupported content type: ${singleContent.type}`);
                                  break;
                          }
                      }

                      // console.log(formJson[formNodeName],node.data.contents);
                      // return data
                  }

                }


            }
          }


        // update btns in condition
        for (const nodeId in data.nodes) {
          const node = data.nodes[nodeId];
          if (node.data.type === "condition") {
            for(let i=0; i< node.data.contents.length;i++){
              const conditionContent = node.data.contents[i]

              if (!conditionContent.value) {
                continue
              }
              // do a loop on formJson and match with button value
              for(const formNodeName in formJson){
                const formNode = formJson[formNodeName];
                for (const formContent of formNode.contents){
                  if (formContent.type === "card") {
                    // loop on buttons update the original button = user input button
                    for(const actionBtn of formContent.actionButtons){
                      if (actionBtn.editable &&(actionBtn.originalBtn === conditionContent.value)) {
                        conditionContent.value = actionBtn.title
                      }
                    }
                  }
                }
              }
            }
          }

          // if data type card
        }


        // make a unique list of buttons
        const resultUniqueBtns = []
        for(const formNodeName in formJson){
          const formNode = formJson[formNodeName];
          for (const formContent of formNode.contents){
            if (formContent.type === "card") {
              for(const actionBtn of formContent.actionButtons){
                // if action button and original button is not same, push into ist uniquely by original name
                // if same skip that item
                // if not same and not exist in result, then push
                // if not same and already exist then replace that in result
                const isTitleChangedByUser = actionBtn.originalBtn && (actionBtn.title !== actionBtn.originalBtn);
                const existingIndex = resultUniqueBtns.findIndex(
                  (btn) => btn.originalBtn === actionBtn.originalBtn
                );
                if (existingIndex === -1) {
                  if (isTitleChangedByUser) {
                    resultUniqueBtns.push(actionBtn);
                  }
                } else {
                  const existingBtn = resultUniqueBtns[existingIndex];
                  if (isTitleChangedByUser) {
                    resultUniqueBtns[existingIndex] = existingBtn;
                  }
                }
              }
            }
          }
        }



        // update btns in all cards
        for (const nodeId in data.nodes) {
          const node = data.nodes[nodeId];
          if (node.data.type === "content") {
            for(let i=0; i< node.data.contents.length;i++){
              const nodeContent = node.data.contents[i]
              // if card type
              if (nodeContent.type === 'card') {
                // look on each action btn in form data and update
                for(let btnIndex=0; btnIndex< nodeContent.actionButtons.length; btnIndex++){
                  // if match with the original button
                  const nodeBtnTitle = nodeContent.actionButtons[btnIndex]?.buttonTitle;
                  const changedBtn = resultUniqueBtns.find(el=>el.originalBtn === nodeBtnTitle);
                  if (changedBtn) {
                    nodeContent.actionButtons[btnIndex].buttonTitle = changedBtn.title;
                    nodeContent.actionButtons[btnIndex].buttonValue = changedBtn.title;
                  }
                }
              }
            }
          }
        }





        // remove conditin response and links
        // console.log({btnsTobeDeletedFromLinks});

        // do loop and remove conditon and links
        const condIdsToBeDelete = [];
        for (const nodeId in data.nodes) {
            const node = data.nodes[nodeId];
            if (node.data && node.data.type === "condition") {
                const deletedConds = node.data.contents.filter(el=>btnsTobeDeletedFromLinks.includes(el.value))
                // console.log(node.data.contents.length);
                node.data.contents = node.data.contents.filter(el=>!btnsTobeDeletedFromLinks.includes(el.value));
                node.contents = node.data.contents.filter(el=>!btnsTobeDeletedFromLinks.includes(el.value));

                // delete the portOuts
                deletedConds.forEach(cond=>{
                    delete node.portsOut[cond.condId]
                })

                condIdsToBeDelete.push(...deletedConds.map(el=>el.condId))
            }
        }

        // remove links for the removed buttons
        for (const linkId in data.links) {
            const link = data.links[linkId];
            if (condIdsToBeDelete.includes(link.start_port)) {
                // delete the link
                delete data.links[linkId]
            }
        }


        // update hidden json
        // do loop on main data
        for(const formNodeName in hiddenJson){
          const formNode = hiddenJson[formNodeName];
          for (const nodeId in data.nodes) {
            const node = data.nodes[nodeId];
            if (node.data && node.data.customName === formNodeName) {
              if (formNode.type === "call-api") {
                const skillheaders = JSON.parse(node.data.skillData.headers);
                skillheaders.accesstoken = formNode.skillData.accesstoken

                if (formNode.skillData?.replaceUrl?.account_id) {
                  node.data.skillData.url = node.data.skillData.url?.replace('{{account_id}}',formNode.skillData?.replaceUrl?.account_id)
                }

                node.data.skillData.headers = JSON.stringify(skillheaders)
              }
            }
          }

        }


        return data;
    } catch (error) {
        console.log(error);
        return data;
    }
}

export const formAndAccountMerger = (merchant,botform,currentUser)=>{
  if (!merchant || !botform || !currentUser) {
    console.log({merchant,botform,currentUser});
    return
  }
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let operatingHoursLine = "";
  // Iterate over business_hours to generate the line
  const formattedBusinessHours = merchant?.business_hours?.map(item => {
      const dayName = daysOfWeek[parseInt(item.day) - 1];
      return `${dayName} ${item.open} - ${item.close}`;
  });
  if (formattedBusinessHours) {
    // join the operation hours
      operatingHoursLine = `${formattedBusinessHours.join(", ")}.`;
  }

  const replaceList = [
    {
      key: "account_id",
      value: currentUser.account_id,
    },
    {
      key: "shop_category_link",
      value: `https://shop.peasy.ai/app/accounts/${currentUser.account_id}/?category={{event.payload.text}}`,
    },
    {
      key: "order_link",
      value: `https://shop.peasy.ai/app/accounts/${currentUser.account_id}/payment`,
    },
  ]

    // content?.extraConfig?.includeGoogleMap
  const googleMapItem = {
    key: "google_map",
    value: `https://www.google.com/maps?q=${merchant?.address?.latitude},${merchant?.address?.longitude}`,
  }

  // content?.extraConfig?.isMentionOperatingHour
  const OperatingHourItem = {
    key: "operating_hours",
    value: operatingHoursLine,
  }


   // Deep copy of botform
  const tempForm = JSON.parse(JSON.stringify(botform||{}));
  for(const node in tempForm.form_data.form_nodes){
      for (const content of tempForm.form_data.form_nodes[node].contents){
          if (content.type === 'card') {
              // update operating hour
              if (content?.extraConfig?.isMentionOperatingHour) {
                  replaceList.push(OperatingHourItem)
              }
          }
          if (content.type === 'text') {
              if (content?.extraConfig?.includeGoogleMap) {
                  replaceList.push(googleMapItem)
              }
          }

      }
  };

  for(const node_name in tempForm.form_data.hidden_nodes){
      const node = tempForm.form_data.hidden_nodes[node_name];
      if (node.type === 'call-api') {
          node.skillData.accesstoken = currentUser.access_token
          node.skillData.replaceUrl.account_id = currentUser.account_id
      }
  }


  // do entire json replace
  const replacementMap = Object.fromEntries(
    replaceList.map(({ key, value }) => [key, value])
  );
  let jsonString = JSON.stringify(tempForm);
  jsonString = jsonString.replace(/\{\{(\w+)\}\}/g, (match, key) =>
    replacementMap[key] !== undefined ? replacementMap[key] : match
  );
  return JSON.parse(jsonString);

}



const crateExpressBotFromTpl = async(userObj,formJson,newFlowBotData,isSubmit=false,flow_api_key,isDev) =>{
    const { userId, accountId, apiAccessToken,} = userObj;
    // step 1: create a bot_id

    // create only in first time
    if (!formJson.id || !formJson.bot_id) {
        const createBotPayload = {
            userId,
            name:formJson.name,
            accountId,
            template:{id:formJson.template_name},
            flow_api_key,
        }
        // step 1: create a bot_id
        const newBot = await FlowBot.createFlow(createBotPayload);

        const newExpressEmptyPayload= {
            bot_express: {
                name: formJson.name,
                template_name: formJson.template_name,
                account_id: accountId,
                // template:false,
                form_data: formJson.form_data
            }
        }
        // step 2: insert the empty form into this account for this bot
        const botCreateRes = await ExpressBot.createExpressBot(accountId,apiAccessToken,newExpressEmptyPayload,isDev);

        // step 3: get the bot_id from DB
        const {data:newExpressBotRes} = await ExpressBot.getExpressBotById(formJson.name,accountId,apiAccessToken,isDev);


        // step 5: get the originalBotData By originalBotId from DB
        const {data:originalBotJson,isSuccess:isOriginalBotSuccess} = await ExpressBot.getOriginalTemplateBotById(newExpressBotRes.id,accountId,apiAccessToken);
        // console.log({originalBotJson});

        // step 6: prepare the bot data based on the defaultFormJson
        const updatedBotData = modifyBot(originalBotJson,newFlowBotData.form_data.form_nodes,newFlowBotData.form_data.hidden_nodes);

        // step 7: update(export) the bot with the prepared data
        if (isSubmit) {
          const exportedResult = await FlowBot.exportUpdateFlow(newExpressBotRes.bot_id,updatedBotData,null,flow_api_key,userId);
        }

        // step 8: get updated form data
        const {data:updatedFormWithId} = await ExpressBot.getExpressBotById(formJson.name,accountId,apiAccessToken,isDev);

        return {isSuccess:true,data:updatedFormWithId};
    }

    // dont need to create, just update the existing
    if (formJson.id && formJson.bot_id) {
        // update the form into DB
        const botUpdateRes = await ExpressBot.updateExpressBot(accountId,apiAccessToken,formJson,formJson.id,isDev);

        // step 5: get the originalBotData By originalBotId from DB
        const {data:originalBotJson,isSuccess:isOriginalBotSuccess} = await ExpressBot.getOriginalTemplateBotById(formJson.id,accountId,apiAccessToken,isDev);

        // step 6: prepare the bot data based on the defaultFormJson
        const updatedBotData = modifyBot(originalBotJson,newFlowBotData.form_data.form_nodes,newFlowBotData.form_data.hidden_nodes);

        // step 7: update(export) the bot with the prepared data

        if (isSubmit) {
          const exportedResult = await FlowBot.exportUpdateFlow(formJson.bot_id,updatedBotData,null,flow_api_key,userId);
        }
        return {isSuccess:true,data:formJson};
    }

    return {isSuccess:false,data:null};
}

const Flow_API_KEY = 'z67XchrN68JW';
export class ExpressBotService {
  constructor({ isDev, userObj}) {
      this.isDev = isDev;
      this.flowApiKey = Flow_API_KEY;
      this.baseServerUrl = isDev? baseServerUrl : baseServerProdUrl;
      this.userObj = userObj // { userId, accountId, apiAccessToken,}
  }

  async createExpressBotByTpl(formJson,newFlowBotData,isSubmit=false){
      return await crateExpressBotFromTpl(this.userObj,formJson,newFlowBotData,isSubmit,this.flowApiKey,this.isDev)
  }



  // arrange the list for expressBot api
  async getAllExpressBots(){
    return await ExpressBot.getExpressBotList(this.userObj.accountId,this.userObj.apiAccessToken,this.isDev)
  }

  async getExpressBotTempByTempName(template_name){
    return await ExpressBot.getExpressBotTempByTempName(this.userObj.accountId,this.userObj.apiAccessToken,template_name,this.isDev)
  }
  async getExpressBotById(express_bot_DB_id){
    return await ExpressBot.getExpressBotById(express_bot_DB_id,this.userObj.accountId,this.userObj.apiAccessToken,this.isDev)
  }

  async createExpressBot(expressBotPayload){
    return await ExpressBot.createExpressBot(this.userObj.accountId,this.userObj.apiAccessToken,expressBotPayload,this.isDev)
  }




  // arrange the list for flowBot API
  async createFlow(newBotName,template_id){
    const createBotPayload = {
      userId: this.userObj.userId,
      name: newBotName,
      accountId: this.userObj.accountId,
      template:{id:template_id},
      flow_api_key: this.flowApiKey,
    }
    return await FlowBot.createFlow(createBotPayload);
  }




}
