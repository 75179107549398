var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ModalTemplate",
        {
          attrs: {
            isOpen: _vm.isOpen,
            "on-close": _vm.onClose,
            modalMainContainerClass: "squidLevelsModal",
            closeBtnClass: "modal_close_btn_left",
            closeIcon: "chevron-left",
            title: _vm.$t("D_BOARD.SQUID.TITLE")
          }
        },
        [
          _c("div", { staticClass: "squidLevelsModal_container" }, [
            _c("div", { staticClass: "card_header" }, [
              _c("div", { staticClass: "switch_btn" }, [
                _c(
                  "span",
                  {
                    class:
                      _vm.active_index === 0 ? "disable_btn" : "enable_btn",
                    on: {
                      click: function($event) {
                        return _vm.goPreviouse(_vm.active_index)
                      }
                    }
                  },
                  [_c("fluent-icon", { attrs: { icon: "chevron-left" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "logo" }, [
                  _c("img", {
                    attrs: { src: _vm.getLevelLogo(_vm.active_index), alt: "" }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "switch_btn" }, [
                _c(
                  "span",
                  {
                    class:
                      _vm.active_index === _vm.levels.length - 1
                        ? "disable_btn"
                        : "enable_btn",
                    on: {
                      click: function($event) {
                        return _vm.goNext(_vm.active_index)
                      }
                    }
                  },
                  [_c("fluent-icon", { attrs: { icon: "chevron-right" } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card_title" }, [
              _c("h2", {}, [_vm._v(_vm._s(_vm.levels[_vm.active_index].name))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "progress_bar" },
              [
                _c("ProgressBar", {
                  attrs: { progress: _vm.gvmCompletePercent }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gmv_lavel" }, [
              _c("h3", [
                _c("span", [_vm._v(_vm._s(_vm.score.total_gmv))]),
                _vm._v(
                  "/" + _vm._s(_vm.levels[_vm.active_index].target_gmv) + " RM"
                )
              ]),
              _vm._v(" "),
              _vm.nextLevel
                ? _c("p", { staticClass: "hint" }, [
                    _vm._v(
                      _vm._s(_vm.$t("D_BOARD.LEVELS.GMV_UPGRADE_1")) +
                        " " +
                        _vm._s(_vm.levels[_vm.active_index].target_gmv) +
                        " " +
                        _vm._s(_vm.$t("D_BOARD.LEVELS.GMV_UPGRADE_2")) +
                        " " +
                        _vm._s(_vm.nextLevel.name)
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.previousLevel
              ? _c("div", { staticClass: "gmv_alert" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("D_BOARD.LEVELS.ALERT_1")) +
                        " " +
                        _vm._s(_vm.previousLevel.target_gmv) +
                        " " +
                        _vm._s(_vm.$t("D_BOARD.LEVELS.ALERT_2"))
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "benifits_wrap" }, [
              _c("h2", { staticClass: "benefit_title" }, [
                _vm._v(
                  _vm._s(_vm.levels[_vm.active_index].name) +
                    " " +
                    _vm._s(_vm.$t("D_BOARD.LEVELS.BENEFIT"))
                )
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "benifits" },
                _vm._l(_vm.benifits, function(item) {
                  return _c("li", { key: item, staticClass: "benifit" }, [
                    _c("p", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("fluent-icon", { attrs: { icon: item.icon } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(_vm._s(item.value))
                      ])
                    ])
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("ul", { staticClass: "docs" }, [
                _c("li", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("D_BOARD.DOC_LIST.TRAINING")) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    [_c("fluent-icon", { attrs: { icon: "chevron-right" } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("D_BOARD.DOC_LIST.EARN_POINT")) +
                      "\n          "
                  ),
                  _c(
                    "span",
                    [_c("fluent-icon", { attrs: { icon: "chevron-right" } })],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "24px" } },
              [
                _c(
                  "woot-button",
                  {
                    attrs: {
                      "class-names": "button--dashboard-outline clode_sq_modal",
                      variant: "outline"
                    },
                    on: { click: _vm.onClose }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE")
                        ) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }