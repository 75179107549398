import { frontendURL } from '../../../helper/URLHelper';
// import SessionWrapper from './WrapperSession.vue';
import GoalWrapper from './GoalWrapper.vue';
import GoalHome from './GoalHome.vue';

/*
  1) go to "config/sidebaritems/fileNameRoutes.js" and add the route in "menuItems"
  2) now add the Component will be render for the added router, Add it is here with child
   3) add this catelogue.routes.js file to '/dashboard/dashboard.routes.js' file to active this route
*/

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/target-dashboard'),
      name: 'goal_dashboard',  // must match with 'toStateName'
      roles: ['administrator', ],
      component: GoalWrapper,
      props: () => {
        return {
          headerTitle: 'Dashboard',
          icon: 'grid',
          showBackButton: false,
         };
      },
      children: [
        {
          path: 'goal',
          name: 'dashboard_gamification', // must match this name in session_onboarding.js file in both "routes array" and "menuItems array"
          component: GoalHome,
          roles: ['administrator'],
        },
      ],

    },
  ],
};

