var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "leaderboard" },
    [
      _c("h2", { staticClass: "board_title" }, [
        _vm._v(_vm._s(_vm.$t("D_BOARD.LEADER_BOARD.TITLE")))
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "board_title title_secondary" }, [
        _vm._v(_vm._s(_vm.$t("D_BOARD.LEADER_BOARD.SECONDARY_TITLE")))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.$t("D_BOARD.LEADER_BOARD.DESCRIPTION")))
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "leaders" },
        _vm._l(_vm.leaderboard, function(item, index) {
          return _c("li", { key: item.id, staticClass: "leader" }, [
            _c("div", { staticClass: "rank" }, [
              _c("div", { class: index > 2 ? "rank_circle_bg" : "" }, [
                _c("span", { class: index < 3 ? "top_rank" : "normal_rank" }, [
                  _vm._v(_vm._s(index + 1))
                ]),
                _vm._v(" "),
                index < 3
                  ? _c(
                      "svg",
                      {
                        staticClass: "badge_bg",
                        attrs: {
                          width: "48",
                          height: "59",
                          viewBox: "0 0 48 59",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c(
                          "g",
                          { attrs: { filter: "url(#filter0_d_1845_15519)" } },
                          [
                            _c("path", {
                              attrs: {
                                d: _vm.badgePath,
                                fill: _vm.getBadgeColor(index)
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("defs", [
                          _c(
                            "filter",
                            {
                              attrs: {
                                id: "filter0_d_1845_15519",
                                x: "0.25",
                                y: "0.742676",
                                width: "47.5",
                                height: "57.509",
                                filterUnits: "userSpaceOnUse",
                                "color-interpolation-filters": "sRGB"
                              }
                            },
                            [
                              _c("feFlood", {
                                attrs: {
                                  "flood-opacity": "0",
                                  result: "BackgroundImageFix"
                                }
                              }),
                              _vm._v(" "),
                              _c("feColorMatrix", {
                                attrs: {
                                  in: "SourceAlpha",
                                  type: "matrix",
                                  values:
                                    "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                  result: "hardAlpha"
                                }
                              }),
                              _vm._v(" "),
                              _c("feOffset"),
                              _vm._v(" "),
                              _c("feGaussianBlur", {
                                attrs: { stdDeviation: "5" }
                              }),
                              _vm._v(" "),
                              _c("feComposite", {
                                attrs: { in2: "hardAlpha", operator: "out" }
                              }),
                              _vm._v(" "),
                              _c("feColorMatrix", {
                                attrs: {
                                  type: "matrix",
                                  values:
                                    "0 0 0 0 0.996078 0 0 0 0 0.741176 0 0 0 0 0 0 0 0 0.5 0"
                                }
                              }),
                              _vm._v(" "),
                              _c("feBlend", {
                                attrs: {
                                  mode: "normal",
                                  in2: "BackgroundImageFix",
                                  result: "effect1_dropShadow_1845_15519"
                                }
                              }),
                              _vm._v(" "),
                              _c("feBlend", {
                                attrs: {
                                  mode: "normal",
                                  in: "SourceGraphic",
                                  in2: "effect1_dropShadow_1845_15519",
                                  result: "shape"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "company" }, [
              _c("h5", [_vm._v(_vm._s(item.company_name))]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("div", [_vm._v(_vm._s(item.total_gmv) + " MYR")]),
                _vm._v(" "),
                item.average_speed > 0
                  ? _c("div", { staticClass: "speed" }, [
                      _c(
                        "span",
                        [_c("fluent-icon", { attrs: { icon: "electricity" } })],
                        1
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(item.average_speed) +
                          "\n            " +
                          _vm._s(_vm.$t("D_BOARD.LEADER_BOARD.SPEED")) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "percent" }, [
              _vm._v(_vm._s(item.percentage_completed) + "%")
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.uiFlags.isFetching
        ? _c("woot-loading-state", {
            attrs: { message: _vm.$t("D_BOARD.LEADER_BOARD.LOADING") }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }