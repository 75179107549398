import { frontendURL } from '../../../../helper/URLHelper';

const goal_dashboard = accountId => ({
  parentNav: 'goal_nav_item',
  routes: [
    // 'session_onboarding_home',
    'goal_dashboard',
    'dashboard_gamification'
  ],
  menuItems: [
    {
      // add the icon with name as outline in "app\javascript\shared\components\FluentIcon\dashboard-icons.json"
      // real icon name with outline = "contact-card-group-outline"
      icon: 'grid',
      label: 'GOAL_DASHBOARD',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/target-dashboard/goal`),
      toStateName: 'dashboard_gamification',
    },
    /*
    {
      icon: 'contact-card-group',
      label: 'SESSION_ONBOARD',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/session`),
      toStateName: 'session_onboarding_home',
    },
    */
  ],
});

export default goal_dashboard;

