<template>
  <section class="score_benifit_container">
    <aside class="benifit_wrap">
        <h2 class="title">{{$t('D_BOARD.SCORE.TITLE')}}</h2>
        <div class="logo_wrap">
            <div class="logo">
                <img :src="score.rank_logo || '~dashboard/assets/images/octo_img.png'" alt="">
            </div>
        </div>
        <div class="benifit_container">
            <h3 class="title">{{score.rank_name}}</h3>
            <ul class="benifits">
                <li v-for="item in benifits" class="benifit" :key="item">
                    <p class="title">{{item.name}}</p>
                    <div class="info">
                        <div class="icon">
                            <fluent-icon :icon="item.icon" />
                        </div>
                        <div class="description">{{item.value}}</div>
                    </div>
                </li>
            </ul>
            <button class="show_btn" @click="toggleBenifitTab">{{$t('D_BOARD.SCORE.BTN')}}</button>
        </div>
    </aside>
    <aside class="tasks_info_container">
        <p class="title">{{$t('D_BOARD.STEP_INFO.TITLE')}}</p>
        <div class="task_label_info">
            <p class="task_label">
                {{score.task_count - score.task_pending_count}}
                {{$t('D_BOARD.STEP_INFO.LABLE_OF')}}
                {{score.task_count}}
                {{$t('D_BOARD.STEP_INFO.LABLE_END')}}
            </p>
            <div class="progress">
                <div class="progress_bar" :style="{ width: complete_percent + '%' }"></div>
            </div>
            <p class="level_name">{{score.level_name}}</p>
            <button class="btn_3d" @click="onClickCompleteNow('complete_tasks')">{{$t('D_BOARD.STEP_INFO.BTN')}}</button>
        </div>
        <div class="sales_label_info">
            <p class="task_label">
                <span>{{score.total_gmv}}</span>/{{score.level_target_gmv}} RM
            </p>
            <div class="progress">
                <div class="progress_bar" :style="{ width: gmv_complete_percent + '%' }"></div>
            </div>
            <p class="level_name">
                {{$t('D_BOARD.STEP_INFO.INSPIRE_PART_1')}}
                {{score.level_target_gmv}}
                {{$t('D_BOARD.STEP_INFO.INSPIRE_PART_2')}}
            </p>
        </div>
    </aside>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    components: { },
    props: {
        toggleBenifitTab:{
            type: Function,
            default: ()=>{}
        },
        onClickCompleteNow:{
            type: Function,
            default: ()=>{}
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            // user: 'getCurrentUser',
            score: 'score/getScore',
        }),
        complete_percent() {
            const complete = this.score.task_count - this.score.task_pending_count;
            if (this.score.task_count === 0) {
                return 0;
            }
            const percent = (complete / this.score.task_count) * 100;
            return parseFloat(percent.toFixed(2));
        },
        gmv_complete_percent() {
            if (this.score.total_gmv === 0) {
                return 0;
            }
            const percent = (this.score.total_gmv / this.score.level_target_gmv) * 100;
            return percent;
        },
        benifits(){
            if (!this.score?.benefits) {
                return []
            }
            const {support,promotion,offline_courses_discount,subscription_fee_discount} = this.score?.benefits || {};

            const result = [];
            const supportEl = {
                name: this.$t(`D_BOARD.SCORE.SUPPORT.LABEL`),
                icon:"help-support",
                value: this.$t(`D_BOARD.SCORE.SUPPORT.${support?.toUpperCase()}`)
            }
            if ('support' in this.score?.benefits) {
                result.push(supportEl)
            }
            const promotionEl = {
                name: this.$t(`D_BOARD.SCORE.PROMOTION.LABEL`),
                icon:"hand-mike",
                value: this.$t(`D_BOARD.SCORE.PROMOTION.${promotion?.toUpperCase()}`)
            }
            if ('promotion' in this.score?.benefits) {
                result.push(promotionEl)
            }

            if ('offline_courses_discount' in this.score?.benefits) {
                let courseDiscount = "";
                if (offline_courses_discount === 0) {
                    courseDiscount = this.$t(`D_BOARD.SCORE.COURSES.NO_DISCOUNT`)
                }else if (offline_courses_discount === 100) {
                    courseDiscount = this.$t(`D_BOARD.SCORE.COURSES.FREE`)
                }else {
                    courseDiscount = offline_courses_discount + "% " +  this.$t(`D_BOARD.SCORE.COURSES.DISCOUNT`)
                }
                const course = {
                    name: this.$t(`D_BOARD.SCORE.COURSES.LABEL`),
                    icon:"discount",
                    value: courseDiscount
                }
                result.push(course)
            }

            if ('subscription_fee_discount' in this.score?.benefits) {
                let subscriptionValue = "";
                if (subscription_fee_discount === 0) {
                    subscriptionValue = this.$t(`D_BOARD.SCORE.SUBSCRIPTION.NO_DISCOUNT`)
                }else if (subscription_fee_discount === 100) {
                    subscriptionValue = this.$t(`D_BOARD.SCORE.SUBSCRIPTION.FREE`)
                }else {
                    subscriptionValue = subscription_fee_discount + "% " +  this.$t(`D_BOARD.SCORE.SUBSCRIPTION.DISCOUNT`)
                }
                const subscription = {
                    name: this.$t(`D_BOARD.SCORE.SUBSCRIPTION.LABEL`),
                    icon:"dollar-round",
                    value: subscriptionValue
                }
                result.push(subscription)
            }

            return result;
        },
    },
    methods: {
        // end methods
    },


}
</script>

<style  lang="scss" scoped>

 .score_benifit_container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    border-radius: 16px;
    padding: 32px;
    background: #F2E7FE;
 }

 .benifit_wrap{
    .title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #8D25F6;
    }
    .logo_wrap{
        margin-top: 24px;
        .logo{
            width: 140px;
            border-radius: 50%;
            overflow: hidden;
            margin: auto;
        }
    }
 }

 .benifit_container{
    margin-top: 24px;
    .title{
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #CD7F32;
    }
    .benifits{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 16px;
        list-style: none;
        margin: 8px 0 0 0;

        .benifit{
            .title{
                font-weight: 500;
                font-size: 12px;
                color: #858585;
            }
            .info{
                display: grid;
                grid-template-columns: 20px 1fr;
                gap: 4px;
                margin-top: 4px;

                .icon{
                    display: block;
                    margin: auto;
                    width: 16px;
                    height: 16px;
                    svg{
                        color: #8D25F6;
                    }
                }
                .description{
                    font-weight: 500;
                    font-size: 12px;
                    color: #1F1F1F;
                }
            }
        }
    }
    .show_btn{
        display: block;
        margin: 16px auto 0 auto;
        padding: 10px 24px;
        border-radius: 6px;
        font-weight: 700;
        font-size: 14px;
        color: #8D25F6;
        border: 1px solid #8D25F6;
    }

 }

 .tasks_info_container{
    text-align: center;
    .title{
        font-size: 16px;
        font-weight: 500;
        color: #1F1F1F;
    }
    .task_label_info,
    .sales_label_info{
        margin-top: 12px;
        padding: 16px;
        border-radius: 10px;
        background-color: #FFFFFF;

        .task_label{
            font-weight: 500;
            font-size: 16px;
            color: #1F1F1F;
            span{
                font-weight: 700;
                font-size: 28px;
                color: #8D25F6;
            }
        }
        .progress{
            height: 12px;
            width: 240px;
            margin: auto;
            background: #F2E7FE;
            border-radius: 16px;
            .progress_bar{
                background: linear-gradient(45deg, #8D25F6, #CDA2FB);
                border-radius: 16px;
                height: 100%;
            }
        }
        .level_name{
            margin-top: 12px;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            color: #858585;
        }
        .btn_3d{
            margin-top: 16px;
            border-radius: 6px;
            background: linear-gradient(180deg,#8D25F6 75%,#531690);
            box-shadow: 0 2px 5px 0 #5907AB40;
            font-weight: 700;
            font-size: 14px;
            padding: 10px 24px;
            color: #fff;
            border: none;
            cursor: pointer;
        }
    }
 }
</style>