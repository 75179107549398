var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col_preview preview" }, [
    _c("div", { staticClass: "pre_header" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.ADD.PREVIEW.TITLE")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pre_message_box" }, [
      _c(
        "div",
        { staticClass: "preview_card" },
        _vm._l(_vm.currentForm.contents, function(content, idx) {
          return _c("div", { key: idx }, [
            content.type === "text"
              ? _c("div", { staticClass: "content" }, [
                  _c("p", [_vm._v(_vm._s(content.value))])
                ])
              : _vm._e(),
            _vm._v(" "),
            content.type === "card"
              ? _c("div", { staticClass: "content" }, [
                  _c("p", [_vm._v(_vm._s(content.title))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(content.subtitle))]),
                  _vm._v(" "),
                  content.actionButtons.length
                    ? _c("div", { staticClass: "buttons" }, [
                        _c(
                          "div",
                          { staticClass: "btn_wrapper" },
                          [
                            _vm._l(content.actionButtons, function(buttonEl) {
                              return [
                                buttonEl.visibility && buttonEl.editable
                                  ? _c("a", { key: buttonEl.title }, [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(buttonEl.title) +
                                          "\n                              "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            content.type === "category_list"
              ? _c("div", { staticClass: "content" }, [
                  _c("p", [_vm._v(_vm._s(content.title))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(content.body))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(content.footer))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c("div", { staticClass: "btn_wrapper" }, [
                      _c("a", [
                        _vm._v(
                          "\n                          " +
                            _vm._s(content.button) +
                            "\n                          "
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            content.type === "image"
              ? _c("div", { staticClass: "content" }, [
                  _c("p", [_vm._v(_vm._s(content.title))])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("time", [_vm._v(_vm._s(_vm.getCurrentTime()))])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }