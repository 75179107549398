<template>
    <div class="column content-box">
        <!-- <woot-button
            color-scheme="success"
            class-names="button--fixed-right-top"
            icon="arrow-download"
            @click="downloadReports"
        >
        {{ downloadButtonLabel }}
        </woot-button> -->
        <section class="flex-container flex-dir-column medium-flex-dir-row">
            <div>
                <multiselect
                    v-model="selected_metric"
                    :options="metricOptions"
                    class="small-12 heat_map"
                    track-by="id"
                    label="name"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    placeholder="Pick Inbox"
                    selected-label
                    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                    :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                    @select="changeFilterSelection"
                />
            </div>
        </section>
        <!-- display chart  -->
        <HeatMapChart
            chartId="salesHeatmapChart"
            :series="chartSeries"
        />
    </div>
</template>

<script>
import HeatMapChart from './HeatMapChart.vue';
import {METRIC_OPTIONS} from "../constants"

export default {
    name: 'WootHeatMap',
    components: {
        HeatMapChart,
    },
    props:{
        downloadButtonLabel:{
            type: String,
            default: "Download Report"
        },
        chartSeries:{
            type: Array,
            default: []
        },
        onFilterSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            selected_metric: METRIC_OPTIONS[0],
            metricOptions: METRIC_OPTIONS,
        // Dynamic series data
        // chartSeries: [
        //     {
        //     name: 'Series 1',
        //     data: [
        //         { x: 'W1', y: 22 },
        //         { x: 'W2', y: 29 },
        //         { x: 'W2', y: 10 },
        //     ],
        //     },
        //     {
        //     name: 'Series 2',
        //     data: [
        //         { x: 'W1', y: 15 },
        //         { x: 'W2', y: 43 },
        //         { x: 'W2', y: 43 },
        //     ],
        //     },
        // ],
        // Custom chart options
            // chartOptions: {
            //     colors: [
            //         '#b5ff00',
            //         '#6F1DDF'
            //     ],
            // },
        };
    },
    // dispatch here dynamically from props
    methods:{
        changeFilterSelection(item){
            if (this.onFilterSelect) {
                this.onFilterSelect(item);
            }
        },
        downloadReports(){},
        // end methods
    },

}
</script>

<style>
/* global style  */
.heat_map .multiselect__single{
    font-size: 14px;
}
</style>
