var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.isOpen,
        "on-close": _vm.onClose,
        modalMainContainerClass: _vm.modalMainContainerClass,
        closeBtnClass: "modal_close_btn_left template_modal_close_btn",
        closeIcon: "chevron-left"
      },
      on: {
        "update:show": function($event) {
          _vm.isOpen = $event
        }
      }
    },
    [
      _c("div", { staticClass: "header_left_close" }, [
        _c("h2", { staticClass: "header_title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "template_modal_body" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }