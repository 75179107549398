<template>
  <div class="column content-box">
    <section>
      <div class="settings--content">
        <div class="row catalouges-section">
          <div class="columns medium-2">
            <h6 class="catalouges-header">{{ $t('CATALOGUE.HEADER.CATAGORY') }}</h6>
          </div>
          <div class="columns medium-10">
            <multiselect
              v-model="selectedCategories"
              :options="categories"
              track-by="id"
              label="title"
              :disabled="isCatalogDisabled(catalog)"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              @tag="createCategory"
              :tag-placeholder="$t('CATALOGUE.ADD_EDIT_FORM.CREATE_CTG_PLACEHOLDER')"
              :taggable="true"
              @remove="deleteCategory"
              :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </div>
          <div class="columns medium-2">
            <h6 class="catalouges-header">{{ $t('CATALOGUE.HEADER.CATALOGUES') }}</h6>
          </div>
          <div class="columns medium-10">
            <div class="row item-header toolbar">
              <input
                @keyup.enter="searchCatalogueHandler"
                class="search"
                type="search"
                :placeholder="$t('CATALOGUE.TOOLBAR.SEARCH_PLACEHOLDER') "
              >
              <div class="column small-2" style="height:30px;">
                <multiselect
                  class="column small-12"
                  v-model="filteredCategories"
                  :options="categories"
                  track-by="id"
                  label="title"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  @select="applyFilterCategory($event)"
                  @remove="applyFilterRemoveCategory($event)"
                />
              </div>
              <woot-button
                :disabled="isCatalogDisabled(catalog)"
                @click="addCatalogueItems"
                style="margin-left: 10px;"
              >
                <fluent-icon icon="add" />
              </woot-button>
            </div>
            <section class="catalogue_items">
              <div v-for="item in catalogueItems" class="item" :key="item.id">
                <div class="action_btns">
                  <woot-button
                    @click="openEditCatalogueItem(item)"
                    style="margin-left: 10px;"
                    variant="clear"
                    color-scheme="secondary"
                    icon="edit"
                    size="small"
                    class="btn-filter"
                  />
                  <woot-button
                    @click="openDeletePopup(item)"
                    style="margin-left: 10px;"
                    variant="clear"
                    color-scheme="secondary"
                    icon="dismiss"
                    size="small"
                    class="btn-filter"
                    :isLoading="uiFlags.isDeleting_catalog_items"
                  />
                </div>
                <div>
                  <!-- <img class="card_img" :src="item.image_link" :alt="item.title" /> -->
                  <SHImage class="card_img" :src="item.image_link" />
                </div>
                <div class="card_info">
                  <div>
                    <h6>{{ item.title }}</h6>
                    <p>{{ item.description }}</p>
                    <div class="badges">
                      <span class="label primary">{{ $t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.PRICE') }}: {{ item.price }}</span>
                    </div>
                  </div>
                  <div>
                    <multiselect
                      class="column small-12"
                      v-model="item.categories"
                      :options="categories"
                      track-by="id"
                      label="title"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :hide-selected="true"
                      :placeholder="$t('CATALOGUE.CARD.CATAGORY_PLACEHOLDER')"
                      selected-label
                      :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                      :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                      @select="assignCategoryToCatalogue($event,item)"
                      @remove="removeCategoryfromCatalogue($event,item)"
                    />
                  </div>
                </div>
              </div>
            </section>


            <div class="loadMore_btn">
              <woot-button
                variant="clear"
                size="small"
                color-scheme="primary"
                :is-loading="uiFlags.isFetching_catalog_items"
                :is-disabled="paginationCompute.isLastPage"
                @click="onPageChange(Number(meta.currentPage)+1)"
              >
                <!-- Load More Contacts -->
                {{paginationCompute.btnText}}
              </woot-button>
            </div>

            <woot-delete-modal
              :show.sync="showDeleteConfirmationPopup"
              :on-close="closeDeletePopup"
              :on-confirm="confirmDeletion"
              title="Do you want to delete this catalog?"
              message="This catalog will be deleted."
              confirm-text="Yes! Delete now."
              reject-text="No! Don't delete."
            />
            <!-- :isLoading="uiFlags.isDeleting_catalog_items" -->
          </div>
        </div>
      </div>
    </section>

    <woot-modal :show.sync="showAddCatalogue" :on-close="hideAddCatalogue">
      <AddCatalogueItem :createCategory="createCategory" :on-close="hideAddCatalogue" />
    </woot-modal>
    <woot-modal :show.sync="showEditCatalogue" :on-close="hideEditCatalogue">
      <EditCatalogueItem :on-close="hideEditCatalogue" :catalogue="selectedCatelogueToEdit" />
    </woot-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CatalogueItemsApi from "../../../../api/catalogue/catalogue"
import methodHelperMixin from '../../../../mixins/methodHelperMixin';
import alertMixin from 'shared/mixins/alertMixin';
import AddCatalogueItem from "./AddCatalogueItem.vue"
import EditCatalogueItem from "./EditCatalogueItem.vue";
import CatalogAPI from "../../../../api/catalogue/catalog";
import SHImage from "../../../../../shopApp/routes/shop/components/SHImage.vue";
import {SIGNUP_FLOW} from "../../../../helper/demoAutomationHelper"

export default {
  components:{
    AddCatalogueItem,
    EditCatalogueItem,
    SHImage,
  },
  mixins:[methodHelperMixin,alertMixin],
  data(){
    return {
      // categories:[],
      searchText:"",
      selectedCategories: [],
      showAddCatalogue: false,
      showEditCatalogue: false,
      selectedCatelogueToEdit: null,
      filteredCategories: [],
      catalog: {},
      // delete ctg
      showDeleteConfirmationPopup: false,
      selectedCtgToDelete: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: "catalog/getUIFlags",
      meta: "catalog/getMeta",
      categories: "catalog/getCatagories",
      catalogueItems: "catalog/getCatalogItems",
    }),
    paginationCompute(){
      const totalPages = Math.ceil(this.meta.count / this.meta.pageSize);
      const isLastPage = this.meta.currentPage >= totalPages;
      const currentCount = Math.min(this.meta.currentPage * this.meta.pageSize, this.meta.count);
      const btnText = `${this.$t('CATALOGUE.PAGINATION_BTN')} (${currentCount}/${this.meta.count})`;

      return{
        isLastPage,
        btnText,
      }
    },

    // end of computed
  },

  watch:{
    categories(newCategories,){
      if (Array.isArray(newCategories)) {
        this.selectedCategories = newCategories.slice()
      }
    },

    // watch end
  },

  mounted() {
    this.getCatalog();
    // dispatch the API fetchers
    this.$store.dispatch("catalog/getCatagories");
    this.$store.dispatch("catalog/getCatalogProductItems");
    this.checkInitialModalOpen();
    // check if auto add modal open
    this.checkInitialAddModalOpen();
  },
  methods:{
    checkInitialAddModalOpen(){
      const stats = localStorage.getItem('stats_redirect');
    console.log({stats});
      if (stats === "true") {
        this.addCatalogueItems();
        localStorage.removeItem('stats_redirect'); 
      }
    },
    onPageChange(page,filterCategoryList=null) {

      this.$store.dispatch("catalog/getPaginatedCatalogProductItems",{
        currentPage:page,
        pageSize:this.meta.pageSize,
        searchText: this.searchText,
        categories: filterCategoryList? filterCategoryList: this.filteredCategories,
      })
    },
    async createCategory(newTag) {
      const newCategoryPayload = {
        title: newTag,
        status: 0
      };
      try {
        await this.$store.dispatch("catalog/createCategory",newCategoryPayload)
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.CREATE_SUCCESS'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.CREATE_FAIL'));
      }
    },
    async deleteCategory(selectedTag) {
      const deleteCategoryPayload = {
        id: selectedTag.id,
      };
      try {
        await this.$store.dispatch("catalog/deleteCategory",deleteCategoryPayload)
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.DELETE_SUCCESS'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.DELETE_FAIL'));
      }
    },
    async assignCategoryToCatalogue(category,catalogue){
      const associatePayload = {
        id : catalogue.id,
        category_id: category.id,
      }
      try {
        const {data} = await CatalogueItemsApi.assignCategoryToCatalogue(associatePayload);
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.ASSIGN_SUCCESS'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.ASSIGN_FAIL'));
      }
    },
    async removeCategoryfromCatalogue(category,catalogue){
      const associatePayload = {
        id : catalogue.id,
        category_id: category.id,
      }
      try {
        const {data} = await CatalogueItemsApi.removeCategoryToCatalogue(associatePayload);
         this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.REMOVE_SUCCESS'));
      } catch (error) {
        console.log(error);
         this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.REMOVE_FAIL'));
      }
    },
    addCatalogueItems() {
      this.showAddCatalogue = true;
    },
    hideAddCatalogue(){
      this.showAddCatalogue = false;
    },
    hideEditCatalogue(){
      this.selectedCatelogueToEdit = null;
      this.showEditCatalogue = false;
    },
    openEditCatalogueItem(catelogue){
      this.selectedCatelogueToEdit = catelogue;
      this.showEditCatalogue = true;
    },
    async deleteCatalogueItem(catalogue){
      try {
        await this.$store.dispatch("catalog/deleteCatalogueItem",{catalogue_id:catalogue.id})
        this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.DELETE_SUCCESS'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.DELETE_FAIL'));
      }
    },
    searchCatalogueHandler(e){
      const value = e.target.value;
      this.searchText = value;
      this.onPageChange(1)
    },
    applyFilterCategory(selectedItem){
      const newFilteredList = [... this.filteredCategories,selectedItem];
      this.onPageChange(1,newFilteredList)
    },
    applyFilterRemoveCategory(selectedItem){
      const newFilteredList = this.filteredCategories.filter(item => item.id !== selectedItem.id);
      this.onPageChange(1,newFilteredList)
    },
    async getCatalog(){
        try {
            const {data} = await CatalogAPI.getDefaultCatalog();
            this.catalog = data;
        } catch (error) {
            console.log("getCatalog = ",error);
            this.showAlert('error to get catalog');
        }
    },
    isCatalogDisabled(catalog){
      let isDisable = true;
      if (catalog?.id) {
        isDisable = false;
      }
      return isDisable;
    },

    openDeletePopup(catalog) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCtgToDelete = catalog;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
      this.selectedCtgToDelete = null;
    },
    confirmDeletion() {
      this.deleteCatalogueItem(this.selectedCtgToDelete);
      this.closeDeletePopup();
    },
    checkInitialModalOpen(){
      const signupFlow = SIGNUP_FLOW.getFlow();
      console.log({signupFlow});
      if(signupFlow?.isNewSignup && signupFlow?.current_step === SIGNUP_FLOW.FLOWS.catalog_item ){
        // open add modal
        this.addCatalogueItems();
      }
    },
    // end method
  },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.catalouges-section {
  padding-top: 20px;
  .catalouges-header {
    color: $color-woot;
  }
  .item-header {
    justify-content: end;
  }

}
.toolbar{
  .search{
    width: fit-content;
  }
}

.catalogue_items{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
  margin-top: $space-smaller;

  .item{
    position: relative;
    width: 250px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    margin-bottom: $space-one;
    border-radius: $space-small;

    .card_img{
      object-fit: cover;
      height: 150px;
      width: 100%;
      border-top-left-radius: $space-small;
      border-top-right-radius: $space-small;
    }
    .card_info{
      padding: $space-small;

      .badges{
        display: flex;
        justify-content: center;
        margin-bottom: $space-slab;

        span{
          font-size: $space-slab;
          font-weight: $font-weight-black;
        }
      }

    }

    .action_btns{
      position: absolute;
      display: none;
      right: 0.4rem;
      top: 0.4rem;
      border-radius: 0.8rem;
      transition: background-color 0.2s ease-in-out;
    }

    &:hover{
      .action_btns{
        display: block;
        button{
          background-color: var(--w-25);
          transition: background-color 0.2s ease-in-out;
        }
      }
    }
    // item end
  }
}

.loadMore_btn button{
  display: block;
  margin: auto;
}

</style>
