import types from "../mutation-types";
import DashboardApi from '../../api/dashboard'

export const state = {
    stats: {
        order: 0,
        conversation: 0,
        contact: 0,
        inventory: 0,
        revenue: 0.0,
        chatbot: 0
    },
    // meta: {
    //     count: 0,
    //     currentPage: 1,
    //     pageSize: 15
    // },
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        isUpdating: false,
    },
};

// list of getters
const getters = {
    getUIFlags(_state){
        return _state.uiFlags;
    },
    // getMeta(_state){
    //     return _state.meta;
    // },
    getStats(_state){
        return _state.stats;
    },
}


// actions list
const actions = {
    fetchStats: async function({commit},queryParams){
        commit(types.SET_D_BOARD_UI_FLAG,{isFetching: true});
        try {
            const {data} = await DashboardApi.getStats(queryParams);
            console.log(data);
            if (data.data) {
                commit(types.SET_D_BOARD_STATS, data.data);
            }
            commit(types.SET_D_BOARD_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_D_BOARD_UI_FLAG,{isFetching: false});
        }
    },

}


// list of mutations
const mutations = {
    // mutate UI flags
    [types.SET_D_BOARD_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },
    [types.SET_D_BOARD_STATS](_state, data) {
        _state.stats = data;
    },


}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
