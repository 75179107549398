<template>
    <section>
      <div v-if="!uiFlags.isFetching" class="stats_container">
        <div v-for="(item,idx) in formatedStats" class="stats_wrap" :class="`stats_${item.label}`" :key="idx">
          <div class="stats">
            <div class="logo">
              <fluent-icon  :icon="item.icon" viewBox="0 0 40 40" />
            </div>
            <div>
              <span class="count">{{item.value}} <span v-if="item.unit" class="unit">{{item.unit}}</span> </span>
            </div>
            <p class="label">{{item.name}}</p>
            <button class="btn" @click="onClickStat(item)">{{item.btn_label}}</button>
          </div>
        </div>
      </div>
      <woot-loading-state
        v-if="uiFlags.isFetching"
        :message="$t('D_BOARD.STATS.LOADING')"
      />

      </section>
</template>

<script>
import { mapGetters } from 'vuex';



export default {
    props:{
    },
    data() {
        return {
          isDragging: false,
          startX: 0,
          scrollLeft: 0,
        }
    },
    computed: {
        ...mapGetters({
            user: 'getCurrentUser',
            inboxes: 'inboxes/getInboxes',
            stats: 'dashboard/getStats',
            uiFlags: 'dashboard/getUIFlags',
            merchant: 'merchant/getMerchant',
        }),
        formatedStats(){
            const result = [];
            Object.entries(this.stats || {}).forEach(([key,value])=>{
                // labels : 'heigh', 'medium', 'low'
                const labels = {
                    low: "low",
                    medium: "medium",
                    heigh: "heigh",
                }
                let item = {
                    id: key,
                    name:"",
                    value: value,
                    unit: "",
                    btn_label:"",
                    label: labels.medium,
                    icon: "",
                }
                switch (key) {
                    case 'order':
                        item.name = this.$t('D_BOARD.STATS.ORDER.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.ORDER.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'order';
                        break;
                    case 'conversation':
                        item.name = this.$t('D_BOARD.STATS.CONVERSATION.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.CONVERSATION.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'conversation';
                        break;
                    case 'contact':
                        item.name = this.$t('D_BOARD.STATS.CONTACT.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.CONTACT.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'user';
                        break;
                    case 'inventory':
                        item.name = this.$t('D_BOARD.STATS.INVENTORY.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.INVENTORY.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'inventory';
                        break;
                    case 'revenue':
                        item.name = this.$t('D_BOARD.STATS.REVENUE.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.REVENUE.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'currency';
                        item.unit = 'RM';
                        break;
                    case 'chatbot':
                        item.name = this.$t('D_BOARD.STATS.CHATBOT.NAME');
                        item.btn_label = this.$t('D_BOARD.STATS.CHATBOT.BTN');
                        item.label = value>0 ? labels.heigh : labels.medium;
                        item.icon = 'chatbot';
                        break;

                    default:
                        break;
                }
                result.push(item)
            })
            return result;
        },
    },
    mounted(){
        // this.$store.dispatch('dashboard/fetchStats',{
        //   start_date: this.from,
        //   end_date: this.to
        // })
    },
    methods: {
        async onClickStat(stats){
            switch (stats.id) {
                case 'order':
                    this.$router.push(`/app/accounts/${this.user.account_id}/catalog/orders`)
                    break;
                case 'inventory':
                    if (this.stats.inventory === 0) {
                        localStorage.setItem("stats_redirect","true")
                    }else{
                        localStorage.removeItem("stats_redirect")
                    }
                    this.$router.push(`/app/accounts/${this.user.account_id}/catalog/list`)
                    break;
                case 'conversation':
                    // but has an inbox
                    if (this.stats.conversation === 0 && this.inboxes?.length>0) {
                        // go to broadcast page
                        this.$router.push(`/app/accounts/${this.user.account_id}/campaigns/one_off`)
                    }else{
                        this.$router.push(`/app/accounts/${this.user.account_id}/dashboard`)
                    }
                    break;
                case 'revenue':
                    // but not payment setup, call merchant
                    console.log(this.merchant,"merchant");
                    if (!this.merchant?.tekkis_hook_configured) {
                        // go to broadcast page
                        this.$router.push(`/app/accounts/${this.user.account_id}/settings/applications`)
                    }else{
                        this.$router.push(`/app/accounts/${this.user.account_id}/reports/sales`)
                    }
                    break;
                case 'chatbot':
                    this.$router.push(`/app/accounts/${this.user.account_id}/campaigns/flows`)
                    break;
                case 'contact':
                    if (this.stats.contact === 0) {
                        localStorage.setItem("stats_redirect","true")
                    }else{
                        localStorage.removeItem("stats_redirect")
                    }
                    this.$router.push(`/app/accounts/${this.user.account_id}/contacts`)
                    break;

                default:
                    break;
            }
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>

// stats START
.stats_container{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.stats_container::-webkit-scrollbar{
  display: none;
}
.stats_wrap{
  flex: 0 0 auto;
  width: 240px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(to bottom right, #ebfccc, #bef533);

  .stats{
    position: relative;
    .logo{
      position: absolute;
      top: 0;
      right: 0;
      width: 36px;
      height: 36px;
      color: #C99DFB;
      display: block;
      height: 45px;
    }

    .count{
      font-weight: 700;
      font-size: 28px;
      line-height: 39px;
      color: #8D25F6;
      .unit{
        font-size: 16px;
        font-weight: 500;
      }
    }
    .label{
      // margin-top: 10px;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -1px;
      text-transform: capitalize;
      color: #1F1F1F;
      background: transparent;
      padding: 0;
      margin: 0;
    }
    .btn{
      margin-top: 16px;
      border: none;
      border-radius: 6px;
      padding: 10px 24px;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -1%;
      text-align: center;
      cursor: pointer;
      text-transform: capitalize;
      color: #8D25F6;
      background: linear-gradient(to right, #fff, #E5E5E5, );
    }
  }
}
// color schema for stats cards
.stats_heigh{
  background: linear-gradient(to bottom right, #ebfccc, #bef533);
  .stats{
    .logo{color: #C99DFB;}
  }
}
.stats_low{
  background: linear-gradient(to bottom right, #FFE5E5, #FF8A8A);
  .stats{
    .logo{color: #FF6666;}

  }
}
.stats_medium{
  background: linear-gradient(to bottom right, #F2E7FE, #C593FB);
  .stats{
    .logo{color: #AE6CF9;}
  }
}
// stats END

</style>

<style>
/* global styles  */
.stats_wrap .stats .logo svg{
  width: 90%;
  height: 90%;
}
</style>
