<template>
  <div class="view-box columns bg-lightX">
      <!-- :header-title="$t(headerTitle)" -->
    <GoalHeader
      button-route="new"
      :icon="icon"
      :header-title="headerTitle"
      :show-back-button="showBackButton"
      :back-url="backUrl"
    />
    <keep-alive v-if="keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
/* eslint no-console: 0 */

import GoalHeader from './GoalHeader.vue';


export default {
  components: {
    GoalHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },


};
</script>

<style>
/* global font classes  */
.font_sm{
  font-size: 12px;
}
.font_md{
  font-size: 14px;
}

</style>
