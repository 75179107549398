var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            padding: "8px",
            "padding-left": "24px",
            "padding-right": "36px",
            color: "grey",
            "align-items": "center"
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.node.data.customName,
                expression: "node.data.customName"
              }
            ],
            staticClass: "inputTitle",
            staticStyle: {
              margin: "0",
              "margin-right": "5px",
              "text-transform": "uppercase"
            },
            attrs: { type: "text", placeholder: _vm.node.title },
            domProps: { value: _vm.node.data.customName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.node.data, "customName", $event.target.value)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              this.node.data.type == "content"
                ? _c("label", { staticClass: "custom-checkbox-container" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("FLOW.WAIT_FOR_USER_MESSAGE")) +
                        "\n        "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.waitForUserMessage,
                          expression: "waitForUserMessage"
                        }
                      ],
                      attrs: { id: "waitForUserMessage", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.waitForUserMessage)
                          ? _vm._i(_vm.waitForUserMessage, null) > -1
                          : _vm.waitForUserMessage
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.waitForUserMessage,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.waitForUserMessage = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.waitForUserMessage = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.waitForUserMessage = $$c
                            }
                          },
                          _vm.onUpdate
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.node.data.type == "call-api"
                ? _c("call-api-view", {
                    attrs: { "skill-data": _vm.node.data.skillData }
                  })
                : _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#f8fafc",
                        "min-height": "100px",
                        "border-radius": "10px",
                        "margin-bottom": "12px",
                        padding: "12px"
                      }
                    },
                    _vm._l(_vm.contents, function(content, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          content.type == "file"
                            ? _c(
                                "div",
                                { staticClass: "content-image-input" },
                                [
                                  content.value == ""
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            "justify-content": "center",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          content.isUploading
                                            ? _c("woot-loading-state", {
                                                attrs: {
                                                  message: _vm.$t(
                                                    "FLOW.UPLOADING"
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    "align-items": "center",
                                                    "justify-content": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upload-btn-wrapper"
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        { staticClass: "btn" },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "FLOW.UPLOAD_FILE"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "file",
                                                          name: "myfile",
                                                          accept:
                                                            "application/pdf"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleFileUpload(
                                                              $event,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t("FLOW.OR")
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: content.url,
                                                        expression:
                                                          "content.url"
                                                      }
                                                    ],
                                                    staticClass: "w3-input",
                                                    staticStyle: {
                                                      margin: "0"
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.$t(
                                                        "FLOW.PLACEHOLDERS.PASTE_FILE_URL"
                                                      )
                                                    },
                                                    domProps: {
                                                      value: content.url
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          content,
                                                          "url",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "12px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "woot-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          content.url.trim()
                                                            .length == 0
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.uploadFromURL(
                                                            index,
                                                            content.url
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FLOW.UPLOAD"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "5px",
                                            margin: "5px",
                                            "border-radius": "5px",
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "flex-direction": "column",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.getFileUrl(
                                                  content.value
                                                ),
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                🔗 " +
                                                  _vm._s(_vm.$t("FLOW.FILE")) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "new-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "FLOW.PLACEHOLDERS.FILE_LABEL"
                                            )
                                          ) +
                                          "\n\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.title,
                                            expression: "content.title"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.THIS_IS"
                                          )
                                        },
                                        domProps: { value: content.title },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "title",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "catalog"
                            ? _c("div", { staticClass: "content-catalog" }, [
                                _c("div", { staticClass: "form" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "catalog-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("FLOW.CATALOG_HEADER")
                                          ) +
                                          "\n                "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.catalog_header,
                                            expression: "content.catalog_header"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VALUE"
                                          )
                                        },
                                        domProps: {
                                          value: content.catalog_header
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "catalog_header",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "catalog-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("FLOW.CATALOG_BODY")) +
                                          "\n                "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.catalog_body,
                                            expression: "content.catalog_body"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VALUE"
                                          )
                                        },
                                        domProps: {
                                          value: content.catalog_body
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "catalog_body",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "catalog-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("FLOW.CATALOG_FOOTER")
                                          ) +
                                          "\n                "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.catalog_footer,
                                            expression: "content.catalog_footer"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VALUE"
                                          )
                                        },
                                        domProps: {
                                          value: content.catalog_footer
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "catalog_footer",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "catalog-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("FLOW.CATALOG_TYPE_LABEL")
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: content.catalog_type,
                                              expression: "content.catalog_type"
                                            }
                                          ],
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                content,
                                                "catalog_type",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.catalogTypes, function(
                                          item
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: item.id,
                                              domProps: { value: item }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(item.name) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.brandProfiles.length > 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { width: "100%" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "catalog-label-text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "FLOW.SELECT_BRAND_PROFILE"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        content.brand_profile,
                                                      expression:
                                                        "content.brand_profile"
                                                    }
                                                  ],
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        content,
                                                        "brand_profile",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.brandProfiles,
                                                  function(item) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: item.api_key,
                                                        domProps: {
                                                          value: item
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              item.brand_name
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("FLOW.NO_BRAND_PROFILE")
                                          )
                                        )
                                      ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItem(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("FluentIcon", {
                                      attrs: { icon: "dismiss" }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "image"
                            ? _c(
                                "div",
                                { staticClass: "content-image-input" },
                                [
                                  content.value == ""
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            "justify-content": "center",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          content.isUploading
                                            ? _c("woot-loading-state", {
                                                attrs: {
                                                  message: _vm.$t(
                                                    "FLOW.UPLOADING"
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    width: "100%"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upload-btn-wrapper"
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        { staticClass: "btn" },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "FLOW.UPLOAD_IMAGE"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "file",
                                                          name: "myfile",
                                                          accept:
                                                            "image/png, image/jpeg, image/gif"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleFileUpload(
                                                              $event,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t("FLOW.OR")
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: content.url,
                                                        expression:
                                                          "content.url"
                                                      }
                                                    ],
                                                    staticClass: "w3-input",
                                                    staticStyle: {
                                                      margin: "0"
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.$t(
                                                        "FLOW.PLACEHOLDERS.PASTE_IMAGE_URL"
                                                      )
                                                    },
                                                    domProps: {
                                                      value: content.url
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          content,
                                                          "url",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "12px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "woot-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          content.url.trim()
                                                            .length == 0
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.uploadFromURL(
                                                            index,
                                                            content.url
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FLOW.UPLOAD"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "5px",
                                            margin: "5px",
                                            "border-radius": "5px",
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "flex-direction": "column",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "object-fit": "contain",
                                              "max-height": "120px"
                                            },
                                            attrs: {
                                              src: _vm.getFileUrl(content.value)
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "new-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "FLOW.PLACEHOLDERS.IMAGE_LABEL"
                                            )
                                          ) +
                                          "\n\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.title,
                                            expression: "content.title"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.THIS_IS"
                                          )
                                        },
                                        domProps: { value: content.title },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "title",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "video"
                            ? _c(
                                "div",
                                { staticClass: "content-image-input" },
                                [
                                  content.value == ""
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            "justify-content": "center",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          content.isUploading
                                            ? _c("woot-loading-state", {
                                                attrs: {
                                                  message: _vm.$t(
                                                    "FLOW.UPLOADING"
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    "align-items": "center",
                                                    "justify-content": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "upload-btn-wrapper"
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        { staticClass: "btn" },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "FLOW.UPLOAD_VIDEO"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        attrs: {
                                                          type: "file",
                                                          name: "myfile",
                                                          accept:
                                                            "video/mp4,video/x-m4v,video/*"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleFileUpload(
                                                              $event,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t("FLOW.OR")
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: content.url,
                                                        expression:
                                                          "content.url"
                                                      }
                                                    ],
                                                    staticClass: "w3-input",
                                                    staticStyle: {
                                                      margin: "0"
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: _vm.$t(
                                                        "FLOW.PLACEHOLDERS.PASTE_VIDEO_URL"
                                                      )
                                                    },
                                                    domProps: {
                                                      value: content.url
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          content,
                                                          "url",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !content.isUploading
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "12px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "woot-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          content.url.trim()
                                                            .length == 0
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.uploadFromURL(
                                                            index,
                                                            content.url
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FLOW.UPLOAD"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "5px",
                                            margin: "5px",
                                            "border-radius": "5px",
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "flex-direction": "column",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "video",
                                            {
                                              staticStyle: {
                                                "border-radius": "15px"
                                              },
                                              attrs: {
                                                width: "400",
                                                controls: ""
                                              }
                                            },
                                            [
                                              _c("source", {
                                                attrs: {
                                                  src: _vm.getFileUrl(
                                                    content.value
                                                  ),
                                                  type: "video/mp4"
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("br")
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "new-label-text",
                                      staticStyle: { width: "100%" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "FLOW.PLACEHOLDERS.VIDEO_LABEL"
                                            )
                                          ) +
                                          "\n\n              "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.title,
                                            expression: "content.title"
                                          }
                                        ],
                                        staticClass: "new-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.THIS_IS"
                                          )
                                        },
                                        domProps: { value: content.title },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "title",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "delete",
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "text"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-bottom": "1.6em",
                                    padding: "5px",
                                    "background-color": "white"
                                  }
                                },
                                [
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: content.value,
                                        expression: "content.value"
                                      }
                                    ],
                                    staticClass: "w3-input",
                                    staticStyle: {
                                      margin: "0",
                                      "background-color": "transparent",
                                      border: "none",
                                      overflow: "hidden"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "FLOW.PLACEHOLDERS.ENTER_MESSAGE_HERE"
                                      )
                                    },
                                    domProps: { value: content.value },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            content,
                                            "value",
                                            $event.target.value
                                          )
                                        },
                                        _vm.resizeTextArea
                                      ],
                                      focus: _vm.resizeTextArea
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "carousel"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "place-content": "center",
                                    "background-color": "white",
                                    "min-height": "100px",
                                    position: "relative",
                                    "border-radius": "10px",
                                    padding: "12px",
                                    border: "1px dashed lightgrey",
                                    "justify-content": "center",
                                    "flex-direction": "column"
                                  }
                                },
                                [
                                  _vm._l(content.cards, function(
                                    subCard,
                                    subIndex
                                  ) {
                                    return _c("div", { key: subIndex }, [
                                      subCard.type == "card"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "margin-bottom": "1.6em",
                                                "background-color": "white",
                                                "font-size": "14px",
                                                padding: "12px",
                                                "border-radius": "12px",
                                                color: "black",
                                                "font-weight": "400",
                                                border: "solid 1px #f1f5f8",
                                                "align-items": "center",
                                                "justify-content":
                                                  "space-between",
                                                width: "400px"
                                              }
                                            },
                                            [
                                              subCard.title
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "black"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            "Card | " +
                                                              subCard.title
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "grey"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FLOW.CARD_EDIT_CLICK_GUIDE_TEXT"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex"
                                                  }
                                                },
                                                [
                                                  _c("woot-button", {
                                                    staticStyle: {
                                                      "margin-right": "5px"
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      variant: "smooth",
                                                      "color-scheme":
                                                        "secondary",
                                                      icon: "edit"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editCard(
                                                          subCard
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("woot-button", {
                                                    attrs: {
                                                      size: "small",
                                                      variant: "smooth",
                                                      "color-scheme": "alert",
                                                      icon: "dismiss"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteCarouselItem(
                                                          content.cards,
                                                          subIndex
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-right": "5px",
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "custom-btn-wrapper" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNewCard(
                                                    content.cards
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                                }
                                              }),
                                              _vm._v(
                                                "\n\n                  " +
                                                  _vm._s(_vm.$t("FLOW.CARD")) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px"
                                        },
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "card"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-bottom": "1.6em",
                                    "background-color": "white",
                                    "font-size": "14px",
                                    padding: "12px",
                                    "border-radius": "12px",
                                    color: "black",
                                    "font-weight": "400",
                                    border: "solid 1px #f1f5f8",
                                    "align-items": "center",
                                    "justify-content": "space-between"
                                  }
                                },
                                [
                                  content.title
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "black" } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                "Card | " + content.title
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticStyle: { color: "grey" } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "FLOW.CARD_EDIT_CLICK_GUIDE_TEXT"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("woot-button", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: {
                                          size: "small",
                                          variant: "smooth",
                                          "color-scheme": "secondary",
                                          icon: "edit"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editCard(content)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("woot-button", {
                                        attrs: {
                                          size: "small",
                                          variant: "smooth",
                                          "color-scheme": "alert",
                                          icon: "dismiss"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteItem(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "dropdown"
                            ? _c("div", { staticClass: "card_node_item" }, [
                                content.title
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "black" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("List | " + content.title) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "grey" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "FLOW.LIST_EDIT_CLICK_GUIDE_TEXT"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("woot-button", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "secondary",
                                        icon: "edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editList(content)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("woot-button", {
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "alert",
                                        icon: "dismiss"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "category_list"
                            ? _c("div", { staticClass: "card_node_item" }, [
                                content.title
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "black" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              "Category List | " + content.title
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "grey" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "FLOW.CTG_LIST_EDIT_CLICK_GUIDE_TEXT"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("woot-button", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "secondary",
                                        icon: "edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editCategoryList(content)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("woot-button", {
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "alert",
                                        icon: "dismiss"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "calendar_list"
                            ? _c("div", { staticClass: "card_node_item" }, [
                                content.title
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "black" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              "Available Calendar List | " +
                                                content.title
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "grey" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "FLOW.CALENDAR_LIST_EDIT_CLICK_GUIDE_TEXT"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("woot-button", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "secondary",
                                        icon: "edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editAvailableCalendarList(
                                            content
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("woot-button", {
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "alert",
                                        icon: "dismiss"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "appointment_list"
                            ? _c("div", { staticClass: "card_node_item" }, [
                                content.title
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "black" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              "Available Appointment List | " +
                                                content.title
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "grey" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "FLOW.APPOINTMENT_LIST.EDIT_CLICK_GUIDE_TEXT"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("woot-button", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "secondary",
                                        icon: "edit"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editAvailableAppointmentList(
                                            content
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("woot-button", {
                                      attrs: {
                                        size: "small",
                                        variant: "smooth",
                                        "color-scheme": "alert",
                                        icon: "dismiss"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "condition-group"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "place-content": "center",
                                    "background-color": "#f8fafc",
                                    "min-height": "100px",
                                    position: "relative",
                                    "border-radius": "10px",
                                    padding: "12px",
                                    border: "1px dashed lightgrey",
                                    "justify-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._l(content.contents, function(
                                        subContent,
                                        subIndex
                                      ) {
                                        return _c(
                                          "div",
                                          { key: subIndex },
                                          [
                                            subContent.type ==
                                            "condition-op-logical"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "filter__join-operator"
                                                  },
                                                  [
                                                    _c("hr", {
                                                      staticClass:
                                                        "operator__line"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              subContent.logicalOperator,
                                                            expression:
                                                              "subContent.logicalOperator"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "operator__select",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              subContent,
                                                              "logicalOperator",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.logicalOperators,
                                                        function(item) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: item.id,
                                                              domProps: {
                                                                value: item
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              : subContent.type.startsWith(
                                                  "condition"
                                                )
                                              ? _c("condition-view", {
                                                  attrs: {
                                                    content: subContent,
                                                    index: subIndex,
                                                    group: content.contents
                                                  },
                                                  on: {
                                                    "delete-condition":
                                                      _vm.deleteGeneral
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "5px",
                                                display: "flex"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-btn-wrapper"
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addNewCondition(
                                                            content.contents
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("dashboard/assets/images/flow-new/btn-add.png")
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "FLOW.SUB_CONDITION"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteConditionItem(
                                            index,
                                            content.condId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px"
                                        },
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : (content.type
                              ? content.type.startsWith("condition")
                              : false)
                            ? _c("condition-view", {
                                attrs: {
                                  content: content,
                                  index: index,
                                  "cond-id": content.condId
                                },
                                on: { "delete-condition": _vm.deleteGeneral }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-setVariable"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "grey",
                                    "font-size": "14px",
                                    "font-weight": "300"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("FLOW.SET_VARIABLE_DASHED")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-setAPIVariable"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "grey",
                                    "font-size": "14px",
                                    "font-weight": "300"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("FLOW.SET_API_VARIABLE_DASHED")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-setAPIVariable"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.valueType,
                                          expression: "content.valueType"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "valueType",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.variableValues, function(item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          domProps: { value: item }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  content.valueType.id == "custom"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.value,
                                            expression: "content.value"
                                          }
                                        ],
                                        staticClass: "answer--text-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VARIABLE_VALUE"
                                          )
                                        },
                                        domProps: { value: content.value },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "value",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-setVariable"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: content.name,
                                        expression: "content.name"
                                      }
                                    ],
                                    staticClass: "answer--text-input",
                                    staticStyle: {
                                      margin: "0",
                                      "margin-right": "5px"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "FLOW.PLACEHOLDERS.VARIABLE_NAME"
                                      )
                                    },
                                    domProps: { value: content.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          content,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.valueType,
                                          expression: "content.valueType"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "valueType",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.variableValues, function(item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          domProps: { value: item }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  content.valueType.id == "custom"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.value,
                                            expression: "content.value"
                                          }
                                        ],
                                        staticClass: "answer--text-input",
                                        staticStyle: { margin: "0" },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VARIABLE_VALUE"
                                          )
                                        },
                                        domProps: { value: content.value },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "value",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-label"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "grey",
                                    "font-size": "14px",
                                    "font-weight": "300"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("FLOW.LABEL_ACTION")) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-label"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.action,
                                          expression: "content.action"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "action",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.labelActions, function(item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          domProps: { value: item }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: content.name,
                                        expression: "content.name"
                                      }
                                    ],
                                    staticClass: "answer--text-input",
                                    staticStyle: {
                                      margin: "0",
                                      "margin-right": "5px"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "FLOW.PLACEHOLDERS.LABEL_NAME"
                                      )
                                    },
                                    domProps: { value: content.name },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          content,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-contact"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "grey",
                                    "font-size": "14px",
                                    "font-weight": "300"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("FLOW.SET_CONTACT_ATTRIBUTE")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-contact"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.attrType,
                                          expression: "content.attrType"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "attrType",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.attrTypes, function(item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          domProps: { value: item }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  content.attrType.id == "standard"
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: content.attr,
                                              expression: "content.attr"
                                            }
                                          ],
                                          staticClass: "filter__question",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                content,
                                                "attr",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.contactAttributes, function(
                                          item
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: item.id,
                                              domProps: { value: item }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item.name) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  content.attrType.id == "custom"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.customAttr,
                                            expression: "content.customAttr"
                                          }
                                        ],
                                        staticClass: "answer--text-input",
                                        staticStyle: {
                                          margin: "0",
                                          "margin-right": "5px"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE"
                                          )
                                        },
                                        domProps: { value: content.customAttr },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "customAttr",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.value,
                                          expression: "content.value"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "value",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.contactAttributesValues,
                                      function(item) {
                                        return _c(
                                          "option",
                                          {
                                            key: item.id,
                                            domProps: { value: item }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.name) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  content.value.id == "custom"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.custom,
                                            expression: "content.custom"
                                          }
                                        ],
                                        staticClass: "answer--text-input",
                                        staticStyle: {
                                          margin: "0",
                                          "margin-right": "5px"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.CUSTOM_VALUE"
                                          )
                                        },
                                        domProps: { value: content.custom },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "custom",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  content.value.id == "variable"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: content.name,
                                            expression: "content.name"
                                          }
                                        ],
                                        staticClass: "answer--text-input",
                                        staticStyle: {
                                          margin: "0",
                                          "margin-right": "5px"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "FLOW.PLACEHOLDERS.VARIABLE_NAME"
                                          )
                                        },
                                        domProps: { value: content.name },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              content,
                                              "name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-status"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "grey",
                                    "font-size": "14px",
                                    "font-weight": "300"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "FLOW.TOGGLE_CONVERSATION_STATUS"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-status"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: content.value,
                                          expression: "content.value"
                                        }
                                      ],
                                      staticClass: "filter__question",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            content,
                                            "value",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.conversationStatuses, function(
                                      item
                                    ) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.id,
                                          domProps: { value: item }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          content.type == "action-jsEval"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "1.6em",
                                    "align-content": "center"
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: content.value,
                                        expression: "content.value"
                                      }
                                    ],
                                    staticClass: "answer--text-input",
                                    staticStyle: {
                                      margin: "0",
                                      "margin-right": "5px"
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "FLOW.PLACEHOLDERS.JSEVAL_VALUE"
                                      )
                                    },
                                    domProps: { value: content.value },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          content,
                                          "value",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  ),
              _vm._v(" "),
              _vm.node.data.type == "content"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-bottom": "24px",
                        "margin-top": "5px",
                        "flex-wrap": "wrap",
                        "column-gap": "5px"
                      }
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewText }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.TEXT")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewImage }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.IMAGE")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewFile }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.FILE")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewVideo }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.VIDEO")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: {
                                click: function($event) {
                                  return _vm.addNewCard(false)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.CARD")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewCarousel }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.CAROUSEL")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewList }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.LIST")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewCategories }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.CATEGORIES")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewAvailableCalendarList }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("FLOW.AVAILABLE_CALENDAR")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewAvailableAppointmentList }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("FLOW.AVAILABLE_APPOINTMENT")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.brandProfiles.length > 0
                        ? _c("div", [
                            _c("div", { staticClass: "custom-btn-wrapper" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  on: { click: _vm.addNewCatalog }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("dashboard/assets/images/flow-new/btn-add.png")
                                    }
                                  }),
                                  _vm._v(
                                    "\n\n              " +
                                      _vm._s(_vm.$t("FLOW.CATALOG")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.node.data.type == "condition"
                ? _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "24px" }
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticStyle: { "margin-right": "3px" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "5px",
                                display: "flex"
                              }
                            },
                            [
                              _c("div", { staticClass: "custom-btn-wrapper" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.addNewCondition()
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("dashboard/assets/images/flow-new/btn-add.png")
                                      }
                                    }),
                                    _vm._v(
                                      "\n\n                  " +
                                        _vm._s(_vm.$t("FLOW.CONDITION")) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "custom-btn-wrapper" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                on: {
                                  click: function($event) {
                                    return _vm.addNewGroupCondition()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/flow-new/btn-add.png")
                                  }
                                }),
                                _vm._v(
                                  "\n\n                " +
                                    _vm._s(_vm.$t("FLOW.GROUP")) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.node.data.type == "action"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-bottom": "24px",
                        "margin-top": "15px"
                      }
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "custom-btn-wrapper" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.addNewActionSetVariable }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("dashboard/assets/images/flow-new/btn-add.png")
                                }
                              }),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(_vm.$t("FLOW.SET_VARIABLE")) +
                                  "\n            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-btn-wrapper",
                            staticStyle: { "margin-left": "5px" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                on: { click: _vm.addNewActionContact }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/flow-new/btn-add.png")
                                  }
                                }),
                                _vm._v(
                                  "\n\n              " +
                                    _vm._s(_vm.$t("FLOW.CONTACT")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-btn-wrapper",
                            staticStyle: { "margin-left": "5px" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                on: { click: _vm.addNewActionLabel }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/flow-new/btn-add.png")
                                  }
                                }),
                                _vm._v(
                                  "\n\n              " +
                                    _vm._s(_vm.$t("FLOW.LABEL")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-btn-wrapper",
                            staticStyle: { "margin-left": "5px" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                on: { click: _vm.addNewActionStatus }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/flow-new/btn-add.png")
                                  }
                                }),
                                _vm._v(
                                  "\n\n              " +
                                    _vm._s(_vm.$t("FLOW.STATUS")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-btn-wrapper",
                            staticStyle: { "margin-left": "5px" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                on: { click: _vm.addNewActionjsEval }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("dashboard/assets/images/flow-new/btn-add.png")
                                  }
                                }),
                                _vm._v(
                                  "\n\n              " +
                                    _vm._s(_vm.$t("FLOW.JSEVAL")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.node.data.type == "action"
                ? _c("label", { staticClass: "custom-checkbox-container" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("FLOW.END_FLOW")) +
                        "\n        "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endFlowAction,
                          expression: "endFlowAction"
                        }
                      ],
                      attrs: { id: "endFlow", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.endFlowAction)
                          ? _vm._i(_vm.endFlowAction, null) > -1
                          : _vm.endFlowAction
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.endFlowAction,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.endFlowAction = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.endFlowAction = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.endFlowAction = $$c
                            }
                          },
                          _vm.onUpdateEndFlow
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" })
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddPopup,
            "on-close": _vm.hideAddPopup,
            "custom-class": "height"
          },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("edit-card", {
            attrs: {
              card: this.currentCard,
              dref: this.dref,
              botid: this.botid
            },
            on: {
              "on-close": _vm.hideAddPopup,
              "card-edit-done": _vm.cardEditDone
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditListPopup,
            "on-close": _vm.hideEditListPopup,
            "custom-class": "height"
          },
          on: {
            "update:show": function($event) {
              _vm.showEditListPopup = $event
            }
          }
        },
        [
          _c("EditList", {
            attrs: {
              list: this.currentList,
              dref: this.dref,
              botid: this.botid
            },
            on: {
              "on-close": _vm.hideEditListPopup,
              "list-edit-done": _vm.listEditDone
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditCategoryListPopup,
            "on-close": _vm.hideEditCategoryListPopup,
            "custom-class": "height"
          },
          on: {
            "update:show": function($event) {
              _vm.showEditCategoryListPopup = $event
            }
          }
        },
        [
          _c("EditListCategory", {
            attrs: {
              list: this.currentCategoryList,
              dref: this.dref,
              botid: this.botid
            },
            on: {
              "on-close": _vm.hideEditCategoryListPopup,
              "list-edit-done": _vm.categorylistEditDone
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditCalendarListPopup,
            "on-close": _vm.hideEditCalenderListPopup,
            "custom-class": "height"
          },
          on: {
            "update:show": function($event) {
              _vm.showEditCalendarListPopup = $event
            }
          }
        },
        [
          _c("EditListAvailableCalendar", {
            attrs: {
              list: this.currentAvailableCalendarList,
              dref: this.dref,
              botid: this.botid
            },
            on: {
              "on-close": _vm.hideEditCalenderListPopup,
              "list-edit-done": _vm.availableCalendarListEditDone
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditAppointmentListPopup,
            "on-close": _vm.hideEditAppointmentListPopup,
            "custom-class": "height"
          },
          on: {
            "update:show": function($event) {
              _vm.showEditAppointmentListPopup = $event
            }
          }
        },
        [
          _c("EditListAppointment", {
            attrs: {
              list: this.availableAppointmentList,
              dref: this.dref,
              botid: this.botid
            },
            on: {
              "on-close": _vm.hideEditAppointmentListPopup,
              "list-edit-done": _vm.appointmentListEditDone
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }